import { FC, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Button, Empty, List, message, Popconfirm, Skeleton, Spin } from 'antd';
import { BsTrash } from 'react-icons/bs';
import { LuPencil } from 'react-icons/lu';
import { FiUsers } from 'react-icons/fi';
import { IoMailUnreadOutline } from 'react-icons/io5';
import Box from 'src/components/Box';
import ContactModal from 'src/components/ContactModal';
import { RootState } from 'src/store';
import {
  getContacts,
  removeContact,
  updateContact,
} from 'src/store/contacts/reducer';
import { updateAuthData } from 'src/store/auth/reducer';
import {
  getPendingInvites,
  removePendingInvite,
} from 'src/store/pendingInvites/reducer';
import apiRequests from 'src/utils/api';
import apiRoutes from 'src/utils/apiRoutes';
import asyncErrorHandler from 'src/utils/asyncErrorHandler';
import { UserResponse } from 'src/types';
import InviteModal from '../../components/InviteModal';

const Contacts: FC = () => {
  const user: UserResponse = useSelector(
    (globalState: any) => globalState.auth.user
  );
  const { loading: contactLoading, data: contacts } = useSelector(
    (globalState: RootState) => globalState.contacts
  );
  const { loading: inviteLoading, data: invites } = useSelector(
    (globalState: RootState) => globalState.pendingInvites
  );
  const [deletingContacts, setDeletingContacts] = useState<string[]>([]);
  const [updatingInvites, setUpdatingInvites] = useState<string[]>([]);
  const [contactToUpdate, setContactToUpdate] = useState<Record<string, any>>();
  const [inviteModal, setInviteModal] = useState(false);

  const dispatch = useDispatch();

  const onDeleteHandler = async (contact: Record<string, any>) => {
    if (deletingContacts.includes(contact.uuid)) return;

    setDeletingContacts((prev) => [...prev, contact.uuid]);

    try {
      await apiRequests.put(
        `${apiRoutes.CONTACTS}/${contact.uuid}`,
        contact.customers.length > 1
          ? {
              customers: contact.customers
                .filter(
                  (customer: any) =>
                    customer.uuid !== user.contact.customer.uuid
                )
                .map((customer: any) => customer.uuid),
            }
          : {
              status: 'inactive',
            }
      );

      message.success('Team member removed');
      dispatch(removeContact(contact.uuid));
    } catch (error) {
      asyncErrorHandler(error);
    } finally {
      setDeletingContacts((prev) => prev.filter((i) => i !== contact.uuid));
    }
  };

  const onCancelInviteHandler = async (uuid: string) => {
    if (updatingInvites.includes(uuid)) return;

    setUpdatingInvites((prev) => [...prev, uuid]);

    try {
      await apiRequests.put(`${apiRoutes.CUSTOMER_INVITE}/${uuid}`, {
        status: 'cancelled',
      });
      dispatch(removePendingInvite(uuid));
      message.success('Invitation canceled');
    } catch (error) {
      asyncErrorHandler(error);
    } finally {
      setUpdatingInvites((prev) => prev.filter((i) => i !== uuid));
    }
  };

  const onResendInviteHandler = async (uuid: string) => {
    if (updatingInvites.includes(uuid)) return;

    setUpdatingInvites((prev) => [...prev, uuid]);

    try {
      await apiRequests.post(`${apiRoutes.CUSTOMER_INVITE}/${uuid}/resend`);
      message.success('Invitation resent');
    } catch (error) {
      asyncErrorHandler(error);
    } finally {
      setUpdatingInvites((prev) => prev.filter((i) => i !== uuid));
    }
  };

  useEffect(() => {
    dispatch(getContacts());
    dispatch(getPendingInvites());
  }, [dispatch]);

  return (
    <>
      {contactToUpdate && (
        <ContactModal
          contact={contactToUpdate}
          onUpdated={(contact) => {
            dispatch(updateContact(contact));

            if (user.contact.uuid === contact.uuid) {
              dispatch(
                updateAuthData({ name: contact.name, email: contact.email })
              );
            }
          }}
          onClose={() => setContactToUpdate(undefined)}
        />
      )}

      <InviteModal
        visible={inviteModal}
        onInviteSent={() => {
          dispatch(getContacts());
          dispatch(getPendingInvites());
        }}
        onClose={() => setInviteModal(false)}
      />

      <div className="mb-2 flex items-center justify-between px-1 font-bold text-blue-600">
        <div>
          <FiUsers className="mr-2 inline-block text-2xl" />

          <span className="align-middle">Team members</span>
        </div>

        <Button
          size="small"
          type="primary"
          onClick={() => setInviteModal(true)}
        >
          Invite a team member
        </Button>
      </div>

      <Box>
        {contactLoading && !contacts ? (
          <Skeleton className="py-4" />
        ) : (
          <List
            itemLayout="horizontal"
            dataSource={contacts?.data}
            locale={{
              emptyText: (
                <Empty
                  description={
                    <span className="text-gray-400">No team members</span>
                  }
                />
              ),
            }}
            renderItem={(item: any) => (
              <List.Item key={item.uuid} className="flex w-full">
                <span className="basis-2/4">{item.name}</span>

                <span className="flex-auto">{item.email}</span>

                <span className="flex-none space-x-3 text-base">
                  <button onClick={() => setContactToUpdate(item)}>
                    <LuPencil />
                  </button>

                  {deletingContacts.includes(item.uuid) ? (
                    <Spin />
                  ) : (
                    <Popconfirm
                      title="Delete the team member"
                      description="Are you sure to delete this team member?"
                      onConfirm={() => onDeleteHandler(item)}
                      okText="Yes"
                      cancelText="No"
                      disabled={user.contact.uuid === item.uuid}
                    >
                      <button
                        className={
                          user.contact.uuid === item.uuid
                            ? 'cursor-not-allowed text-gray-300'
                            : undefined
                        }
                      >
                        <BsTrash />
                      </button>
                    </Popconfirm>
                  )}
                </span>
              </List.Item>
            )}
          />
        )}
      </Box>

      {inviteLoading || invites?.data?.length ? (
        <>
          <div className="mb-2 mt-4 px-1 font-bold text-blue-600">
            <IoMailUnreadOutline className="mr-2 inline-block text-2xl" />

            <span className="align-middle">Pending invites</span>
          </div>

          <Box>
            {inviteLoading && (!invites || !invites?.data?.length) ? (
              <Skeleton className="py-4" />
            ) : (
              <List
                itemLayout="horizontal"
                dataSource={invites?.data}
                renderItem={(item: any) => (
                  <List.Item key={item.uuid} className="flex w-full">
                    <span className="flex-auto">{item.email}</span>

                    <span className="flex-none space-x-3">
                      <button
                        className="text-blue-700"
                        disabled={updatingInvites.includes(item.uuid)}
                        onClick={() => onResendInviteHandler(item.uuid)}
                      >
                        Resend
                      </button>
                      <Popconfirm
                        title="Cancel invite"
                        description="Are you sure to cancel the invite to this email?"
                        onConfirm={() => onCancelInviteHandler(item.uuid)}
                        okText="Yes"
                        cancelText="No"
                        disabled={updatingInvites.includes(item.uuid)}
                      >
                        <button className="text-red-500">Cancel</button>
                      </Popconfirm>
                    </span>
                  </List.Item>
                )}
              />
            )}
          </Box>
        </>
      ) : undefined}
    </>
  );
};

export default Contacts;
