import Quill from 'quill';
import { BaseTooltip } from 'quill/themes/base';
import { Range } from 'quill/core/selection';
import ClickableLink from './ClickableLink';

export default class CustomTooltip extends BaseTooltip {
  hideTooltipTimer?: NodeJS.Timeout;

  constructor(quill: Quill, bounds?: HTMLElement | string) {
    super(quill, bounds ?? quill.root);
    this.preview = this.root.querySelector('a.ql-preview') as HTMLElement;
  }

  listen() {
    super.listen();

    this.quill.root.addEventListener('mouseover', ({ target }) => {
      const { selection } = this.quill;

      if (CustomTooltip.isEventTargetLink(target) && !selection.mouseDown) {
        clearTimeout(this.hideTooltipTimer);

        if (
          this.root.classList.contains('ql-editing') &&
          !this.root.classList.contains('ql-hidden')
        ) {
          return;
        }

        const nativeRange = document.createRange();
        nativeRange.setStart(target, 0);

        const range = selection.normalizedToRange(
          selection.normalizeNative(nativeRange)
        );

        const [link, offset] = this.quill.scroll.descendant(
          ClickableLink,
          range.index
        );

        if (!link) {
          delete this.linkRange;
          delete this.targetEl;
          return;
        }

        this.targetEl = target;

        this.linkRange = new Range(range.index - offset, link.length());

        const preview = target.getAttribute('href');

        this.preview.textContent = preview;
        this.preview.setAttribute('href', preview || '');

        this.show();
        this.position(
          this.quill.getBounds(this.linkRange.index, this.linkRange.length)
        );
      }
    });

    this.quill.root.addEventListener('mouseout', ({ target }) => {
      if (CustomTooltip.isEventTargetLink(target)) {
        this.hideTooltipTimer = setTimeout(
          () => this.hideTooltipPreview(),
          400
        );
      }
    });

    this.root.addEventListener('mouseleave', () => {
      this.hideTooltipTimer = setTimeout(() => this.hideTooltipPreview(), 400);
    });

    this.root.addEventListener('mouseenter', () => {
      clearTimeout(this.hideTooltipTimer);
    });

    this.root
      .querySelector('a.ql-action')
      ?.addEventListener('click', (event) => {
        if (this.root.classList.contains('ql-editing')) {
          this.save();
        } else {
          if (this.targetEl) {
            this.quill.selection.setNativeRange(this.targetEl);
            this.quill.update('user');
            this.edit('link', this.preview.textContent);
          }
        }
        event.preventDefault();
      });

    this.root
      .querySelector('a.ql-remove')
      ?.addEventListener('click', (event) => {
        if (this.linkRange != null) {
          let range = this.linkRange;
          this.restoreFocus();
          this.quill.formatText(range, 'link', false, 'user');
          delete this.linkRange;
        }
        event.preventDefault();
        this.hide();
      });

    this.quill.on('selection-change', (range, oldRange, source) => {
      if (range == null) return;
      if (range.length === 0 && source === 'user') {
        const [link] = this.quill.scroll.descendant(ClickableLink, range.index);
        if (!link) {
          return;
        }
      } else {
        delete this.linkRange;
      }
      this.hide();
    });
  }

  show() {
    super.show();
    this.root.removeAttribute('data-mode');
  }

  hide() {
    super.hide();
    delete this.targetEl;
  }

  hideTooltipPreview() {
    if (!this.root.classList.contains('ql-editing')) {
      this.hide();
    }
  }

  static isEventTargetLink(
    target: EventTarget | null
  ): target is HTMLAnchorElement {
    return target !== null && (target as HTMLAnchorElement).tagName === 'A';
  }
}

CustomTooltip.TEMPLATE = [
  '<a class="ql-preview" rel="noopener noreferrer" target="_blank" href="about:blank"></a>',
  '<input type="text" data-formula="e=mc^2" data-link="https://quilljs.com" data-video="Embed URL">',
  '<a class="ql-action"></a>',
  '<a class="ql-remove"></a>',
].join('');
