import { ScrollRestoration } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { RootState } from 'src/store';
import Booking from './index';

const BookingRoute = () => {
  const user = useSelector((globalState: RootState) => globalState.auth.user);

  if (user) {
    return (
      <>
        <ScrollRestoration />
        <Booking />
      </>
    );
  }

  return (
    <div className="h-full min-h-screen bg-white p-4">
      <ScrollRestoration />
      <Booking showHomeButton />
    </div>
  );
};

export default BookingRoute;
