import { FC, useLayoutEffect, useState } from 'react';
import { Alert, Form, Input, Modal } from 'antd';
import { useForm } from 'antd/es/form/Form';
import { TbMail } from 'react-icons/tb';
import { BsBuilding } from 'react-icons/bs';
import apiRequests from 'src/utils/api';
import apiRoutes from 'src/utils/apiRoutes';
import asyncErrorHandler from 'src/utils/asyncErrorHandler';

interface RequestAccessModalProps {
  open: boolean;
  onClose: () => void;
}

const RequestAccessModal: FC<RequestAccessModalProps> = ({ open, onClose }) => {
  const [loading, setLoading] = useState(false);
  const [formSent, setFormSent] = useState(false);
  const [form] = useForm();

  const onSubmitHandler = () => {
    setLoading(true);

    form
      .validateFields()
      .then(async (values) => {
        try {
          await apiRequests.post(apiRoutes.PROJECT_REQUEST, {
            ...values,
            description: 'Lionshare access request from login page',
          });

          form.resetFields();

          setFormSent(true);
        } catch (error) {
          asyncErrorHandler(error);
        }
      })
      .finally(() => {
        setLoading(false);
      });
  };

  useLayoutEffect(() => {
    if (open) {
      setFormSent(false);
    }
  }, [open]);

  return (
    <Modal
      open={open}
      title="Request access"
      okText="Submit"
      cancelText="Close"
      onOk={onSubmitHandler}
      footer={formSent ? null : undefined}
      confirmLoading={loading}
      onCancel={loading ? undefined : onClose}
    >
      {!formSent ? (
        <Form
          form={form}
          autoComplete="off"
          size="large"
          validateTrigger={['onSubmit', 'onBlur']}
          style={{ paddingTop: 14 }}
        >
          <Form.Item
            name="company_name"
            rules={[
              {
                required: true,
                message: 'Please input your company name!',
              },
            ]}
          >
            <Input
              prefix={<BsBuilding />}
              placeholder="Company name"
              className="w-full"
            />
          </Form.Item>

          <Form.Item
            name="from_email"
            rules={[
              {
                required: true,
                message: 'Please input your email!',
                type: 'email',
              },
            ]}
          >
            <Input prefix={<TbMail />} placeholder="Email" className="w-full" />
          </Form.Item>
        </Form>
      ) : (
        <Alert
          message="Request access sent successful"
          description="Roaring good news! Request received."
          type="success"
          showIcon
        />
      )}
    </Modal>
  );
};

export default RequestAccessModal;
