import LaravelEcho from 'laravel-echo';
import Pusher from 'pusher-js';
import apiRequests from './utils/api';
import apiRoutes from './utils/apiRoutes';
import ENVIRONMENT from './utils/environments';

const PusherClient = new Pusher(ENVIRONMENT.REACT_APP_PUSHER_KEY, {
  cluster: ENVIRONMENT.REACT_APP_PUSHER_CLUSTER,
  channelAuthorization: {
    transport: 'ajax',
    endpoint: '',
    customHandler: async ({ socketId, channelName }, callback) => {
      try {
        const response = await apiRequests.post(
          `${apiRoutes.BASE_URL}/broadcasting/auth`,
          {
            socket_id: socketId,
            channel_name: channelName,
          }
        );

        callback(null, response.data);
      } catch (error) {
        callback(error as Error, null);
      }
    },
  },
});

const Echo = new LaravelEcho({
  broadcaster: 'pusher',
  key: ENVIRONMENT.REACT_APP_PUSHER_KEY,
  client: PusherClient,
});

export default Echo;
