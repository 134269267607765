import React from 'react';
import { Form, Typography } from 'antd';
import FormQuestions from '../FormQuestions';

const { Title, Text } = Typography;

export interface FormBlockProps {
  state: any;
  form?: any;
  initState?: any;
  disabled?: boolean;
}

const FormBlock: React.FC<FormBlockProps> = ({
  state,
  form,
  initState,
  disabled,
}) => {
  return (
    <div className={`form-view w-full`}>
      <div className="show-in-print mb-[20px]">
        <Text className="!text-blue-500">
          Project: {state?.data?.project?.title}
        </Text>

        <br />

        <Text className="!text-blue-500">
          Company : {state?.data?.project?.customer_business_name}
        </Text>
      </div>

      <Title className="mb-[10px]" level={3}>
        {state.data?.title}
      </Title>

      <span>{state.data?.description}</span>

      <div className="mb-[30px]" />

      <Form
        size="large"
        className="blue-labels default-form"
        form={form}
        initialValues={initState}
        layout="vertical"
      >
        <FormQuestions
          questions={state.data?.questions ?? []}
          disabled={disabled}
        />
      </Form>
    </div>
  );
};

export default FormBlock;
