import { createSlice } from '@reduxjs/toolkit';
import { ApiResponse } from 'src/types';

export interface PendingFormsStateType {
  loading: boolean;
  data: ApiResponse<any> | null;
  error: null | string;
}

const initialState: PendingFormsStateType = {
  loading: false,
  data: null,
  error: null,
};

export const pendingFormsSlice = createSlice({
  name: 'pendingForms',
  initialState,
  reducers: {
    getPendingForms: () => {},
    setPendingFormsLoading: (state) => ({ ...state, loading: true }),
    setPendingFormsData: (state, action) => ({
      ...state,
      loading: false,
      data: action.payload,
    }),
    clearPendingFormsData: () => initialState,
    setPendingFormsError: (state, action) => ({
      ...state,
      loading: false,
      error: action.payload,
    }),
  },
});

export const {
  setPendingFormsData,
  setPendingFormsError,
  setPendingFormsLoading,
  getPendingForms,
  clearPendingFormsData,
} = pendingFormsSlice.actions;

export default pendingFormsSlice.reducer;
