import auth from './auth/reducer';
import tasks from './tasks/reducer';
import dashboardTasks from './dashboardTasks/reducer';
import pendingTasks from './pendingTasks/reducer';
import pendingForms from './pendingForms/reducer';
import projectRetainer from './projectRetainer/reducer';
import dashboardProjects from './dashboardProjects/reducer';
import inbox from './inbox/reducer';
import activities from './activities/reducer';
import projectAdvertising from './projectAdvertising/reducer';
import workLogs from './workLogs/reducer';
import contacts from './contacts/reducer';
import pendingInvites from './pendingInvites/reducer';

const combinedReducer = {
  auth,
  tasks,
  projectRetainer,
  inbox,
  dashboardProjects,
  dashboardTasks,
  activities,
  pendingTasks,
  projectAdvertising,
  pendingForms,
  workLogs,
  contacts,
  pendingInvites,
};

export default combinedReducer;
