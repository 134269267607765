import { AxiosResponse } from 'axios';
import {
  call,
  put,
  select,
  StrictEffect,
  takeLatest,
} from 'redux-saga/effects';
import apiRequests from 'src/utils/api';
import apiRoutes from 'src/utils/apiRoutes';
import {
  getPendingInvites,
  setPendingInvitesData,
  setPendingInvitesError,
  setPendingInvitesLoading,
} from './reducer';

const { get } = apiRequests;

function* getPendingInvitesSagaListener(): any {
  const customer = yield select((state) => state.auth.user?.contact?.customer);

  if (!customer) return;

  yield put(setPendingInvitesLoading());

  try {
    const res: AxiosResponse = yield call(get, apiRoutes.CUSTOMER_INVITE, {
      'filters[status][]': 'pending',
      'filters[customer.uuid][]': customer.uuid,
    });

    yield put(setPendingInvitesData(res.data));
  } catch (error) {
    yield put(setPendingInvitesError(error));
  }
}
function* pendingInvitesSaga(): Generator<StrictEffect> {
  yield takeLatest(getPendingInvites.type, getPendingInvitesSagaListener);
}

export default pendingInvitesSaga;
