import { Form, Input } from 'antd';
import { FC } from 'react';

interface ShortProps {
  question: any;
  disabled: boolean;
}

const Short: FC<ShortProps> = ({ question, disabled }) => {
  return (
    <Form.Item
      label={question.label}
      name={question.id}
      rules={[
        { required: question.required, message: 'This field is required' },
      ]}
    >
      <Input readOnly={disabled} />
    </Form.Item>
  );
};

export default Short;
