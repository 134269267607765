import React from 'react';
import ReactDOM from 'react-dom/client';
import { ConfigProvider } from 'antd';
import './sentry';
import './global.css';
import RouterProvider from './routes';
import * as serviceWorkerRegistration from './serviceWorkerRegistration';
import reportWebVitals from './reportWebVitals';
import { StyleProvider } from '@ant-design/cssinjs';
import { Provider } from 'react-redux';
import store from './store';
import dayjs from 'dayjs';
import 'simplebar-react/dist/simplebar.min.css';

import relativeTime from 'dayjs/plugin/relativeTime';
import PushNotification from './components/PushNotification';

dayjs.extend(relativeTime);

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);

root.render(
  <ConfigProvider
    theme={{
      token: {
        colorPrimary: '#0044CC',
        screenXS: 640,
        screenXSMax: 640,
        screenSMMin: 640,
        screenSM: 640,
        screenMD: 768,
        screenLG: 1024,
        screenXL: 1280,
        borderRadius: 4,
      },
    }}
  >
    <StyleProvider hashPriority="high">
      <Provider store={store}>
        <RouterProvider />
        <PushNotification />
      </Provider>
    </StyleProvider>
  </ConfigProvider>
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA
serviceWorkerRegistration.register();

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
