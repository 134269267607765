import { Button, Form, Input, Tabs, message } from 'antd';
import { FC, useState } from 'react';
import { Navigate, useLocation, useNavigate } from 'react-router-dom';
import LionshareSVG from 'src/icons/LionshareSvg';
import apiRequests from 'src/utils/api';
import apiRoutes from 'src/utils/apiRoutes';
import asyncErrorHandler from 'src/utils/asyncErrorHandler';

interface ResetScreenProps {}

const { Password } = Input;
const ResetScreen: FC<ResetScreenProps> = () => {
  const [state, setState] = useState({
    loading: false,
  });

  const location = useLocation();
  const { search } = location;
  const params = new URLSearchParams(search);
  const token = params.get('token');
  const email = params.get('email');

  const navigate = useNavigate();

  if (!token || !email) {
    return <Navigate replace to="/login" />;
  }

  const handleUpdatePassword = async (values: any) => {
    try {
      setState((prevState) => ({ ...prevState, loading: true }));
      await apiRequests.post(apiRoutes.RESET_PASSWORD, {
        email,
        token,
        password: values.password,
        password_confirmation: values.password,
      });
      setState((prevState) => ({ ...prevState, loading: false }));
      navigate('/login');
      message.success('Password updated!');
    } catch (error) {
      asyncErrorHandler(error);
      setState((prevState) => ({ ...prevState, loading: false }));
    }
  };

  return (
    <div className="flex h-screen w-full flex-col md:flex-row">
      <div className="flex w-full flex-col items-center justify-center bg-[#0044cc] p-4 sm:h-full sm:p-2">
        <div className="max-w-[140px] md:max-w-[440px]">
          <LionshareSVG />
        </div>
        <div className="hidden max-w-[400px] pt-6 sm:block">
          <p className=" px-4 font-light text-white  md:text-[18px]">
            Our all-in-one solution that connects you with our team from any
            device, providing a request system, real-time notifications,
            resources, and more.
          </p>
        </div>
      </div>

      <div className="flex h-full w-full flex-col items-center justify-center ">
        <Tabs defaultActiveKey="1" size="large">
          <Tabs.TabPane tab="Create New password" key="1">
            <div className="min-h-[300px] w-[220px]">
              <Form
                onFinish={handleUpdatePassword}
                name="login"
                autoComplete="off"
                size="large"
                validateTrigger={['onSubmit', 'onBlur']}
              >
                <Form.Item
                  name="password"
                  rules={[
                    {
                      required: true,
                      message: 'This field is required',
                    },
                  ]}
                >
                  <Password
                    autoComplete="new-password"
                    placeholder="Enter new password"
                    size="large"
                  />
                </Form.Item>
                <Form.Item>
                  <Button
                    loading={state?.loading}
                    type="primary"
                    shape="round"
                    htmlType="submit"
                    block
                  >
                    Reset
                  </Button>
                </Form.Item>
              </Form>
            </div>
          </Tabs.TabPane>
        </Tabs>
      </div>
    </div>
  );
};

export default ResetScreen;
