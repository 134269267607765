import { Navigate, useSearchParams } from 'react-router-dom';

const RedirectProjectPage = () => {
  const [searchParams] = useSearchParams();
  const commentId = searchParams.get('target_comment_id');

  let url = '/dashboard';

  url += commentId ? `?target_comment_id=${commentId}` : '';

  return <Navigate to={url} replace />;
};

export default RedirectProjectPage;
