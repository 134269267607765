import { FC } from 'react';
import dayjs from 'dayjs';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { Checkbox, Empty, Skeleton, Spin } from 'antd';
import { TbSubtask } from 'react-icons/tb';
import { RxQuestionMarkCircled } from 'react-icons/rx';
import StatusTag from 'src/components/StatusTag';
import { useAppTour } from 'src/context/appTourContext';
import { TaskResponse, UserResponse } from 'src/types';
import apiRequests from 'src/utils/api';
import apiRoutes from 'src/utils/apiRoutes';
import asyncErrorHandler from 'src/utils/asyncErrorHandler';
import TaskLastComment from './TaskLastComment';
import { decreaseInboxUnreadCount, getInbox } from '../../store/inbox/reducer';

const itemsClassName = {
  lg: {
    container: 'lg:min-h-[46px] lg:space-y-0 lg:gap-3',
    col1: 'lg:flex-1',
    col2: 'lg:w-[230px]',
    col3: 'lg:w-5/12 lg:block',
    statusCol: 'lg:text-center',
  },
  none: {
    container: '',
    col1: '',
    col2: '',
    col3: '',
    statusCol: '',
  },
};

interface TaskProps {
  className?: string;
  data: TaskResponse[] | undefined;
  loading?: boolean;
  showTourButton?: boolean;
  layoutBreakpoint?: keyof typeof itemsClassName;
  showCompletedValue?: boolean;
  onShowCompleted?: (showAll: boolean) => void;
  onMarkAsRead?: (uuid: string, user: UserResponse) => void;
}

const Task: FC<TaskProps> = ({
  className = '',
  data,
  loading = false,
  showTourButton = false,
  layoutBreakpoint = 'lg',
  showCompletedValue,
  onShowCompleted,
  onMarkAsRead,
}) => {
  const user: UserResponse = useSelector(
    (globalState: any) => globalState.auth.user
  );

  const { setOpenTour } = useAppTour();

  const dispatch = useDispatch();

  const findRelatedUser = (item: TaskResponse) => {
    return item.comment_latest?.related_users.find((x) => x.uuid === user.uuid);
  };

  const markAsRead = (item: TaskResponse) => {
    const relatedUser = findRelatedUser(item);

    if (!item.comment_latest || !relatedUser || relatedUser.read_at) {
      return;
    }

    apiRequests
      .post(`${apiRoutes.MENTIONS}/${item.comment_latest.uuid}/read`)
      .then(() => dispatch(getInbox()))
      .catch((error) => asyncErrorHandler(error));

    onMarkAsRead?.(item.uuid, user);

    dispatch(decreaseInboxUnreadCount(1));
  };

  return (
    <div className={`flex w-full flex-col gap-2 ${className}`}>
      <div className="flex items-center gap-2 text-blue-600">
        <div className="flex flex-auto items-center gap-2">
          <TbSubtask className="text-2xl" />

          <span className="pr-2 font-bold">Tasks</span>
        </div>

        {showTourButton || onShowCompleted ? (
          <div className="flex items-center gap-2">
            {loading && data && <Spin size="small" />}

            {onShowCompleted && (
              <Checkbox
                checked={showCompletedValue}
                onChange={(event) => onShowCompleted(event.target.checked)}
              >
                <span className="text-blue-600">Show completed</span>
              </Checkbox>
            )}
            {showTourButton && (
              <button
                type="button"
                onClick={() => {
                  setOpenTour(true, 6);
                }}
              >
                <RxQuestionMarkCircled className="text-2xl" />
              </button>
            )}
          </div>
        ) : undefined}
      </div>

      <div>
        {loading && !data ? (
          <Skeleton className="py-4" />
        ) : (
          <div className="space-y-3">
            {data?.map((task) => {
              const dueIn = dayjs(task.task_end_date ?? task.task_start_date);
              const nowDate = dayjs().startOf('day');

              return (
                <Link
                  key={task.uuid}
                  to={`?task_id=${task.uuid}`}
                  className={`box-content flex flex-wrap items-center gap-2 rounded-md bg-white p-3 hover:bg-[#0000000f] ${itemsClassName[layoutBreakpoint].container}`}
                  preventScrollReset
                  onClick={() => markAsRead(task)}
                >
                  <div
                    className={`flex-auto text-sm font-bold leading-none ${itemsClassName[layoutBreakpoint].col1}`}
                  >
                    {task.task_title}
                  </div>

                  <div
                    className={`flex items-center justify-between gap-3 ${
                      dueIn.isValid() ? 'w-full' : ''
                    } ${itemsClassName[layoutBreakpoint].col2}`}
                  >
                    <div className="flex-1 text-xs">
                      {dueIn > nowDate
                        ? `Due ${dueIn.from(nowDate)}`
                        : undefined}
                      {dueIn < nowDate
                        ? `Past due ${dueIn.from(nowDate, true)}`
                        : undefined}
                      {dueIn.isSame(nowDate) ? 'Due today' : undefined}
                    </div>

                    <div
                      className={`flex-1 text-right ${itemsClassName[layoutBreakpoint].statusCol}`}
                    >
                      <StatusTag value={task.task_status} />
                    </div>
                  </div>

                  <div
                    className={`w-full ${
                      !task.comment_latest ? 'hidden' : ''
                    } ${itemsClassName[layoutBreakpoint].col3}`}
                  >
                    {task.comment_latest ? (
                      <Link
                        to={`?task_id=${task.uuid}&target_comment_id=${task.comment_latest.uuid}`}
                      >
                        <TaskLastComment comment={task.comment_latest} />
                      </Link>
                    ) : (
                      <div />
                    )}
                  </div>
                </Link>
              );
            })}

            {!data?.length ? (
              <Empty
                description={
                  <span className="text-gray-400">
                    You don't have any tasks, click on the green New Task button
                    to create a new task
                  </span>
                }
              />
            ) : undefined}
          </div>
        )}
      </div>
    </div>
  );
};

export default Task;
