import { FC } from 'react';
import { TbForms, TbUserCircle } from 'react-icons/tb';
import { List, Skeleton } from 'antd';
import Box from '../../components/Box';
import { AnswerResponse } from 'src/types';
import StatusTag from '../StatusTag';
import { useLocation, useNavigate } from 'react-router-dom';
import FormDrawer from '../FormDrawer';
import { IoFolderOpen } from 'react-icons/io5';
import { IoIosArrowForward } from 'react-icons/io';

interface FormsProps {
  className?: string;
  boxClassName?: string;
  data?: AnswerResponse[];
  loading?: boolean;
}

const Forms: FC<FormsProps> = ({ className, boxClassName, data, loading }) => {
  const navigate = useNavigate();
  const location = useLocation();

  return (
    <>
      <FormDrawer />

      <div className={`flex w-full flex-col gap-2 ${className}`}>
        <div className="flex items-center gap-2 text-blue-600">
          <TbForms className="text-2xl" />

          <span className="font-bold">Forms</span>
        </div>

        <Box size="default" sizeType="relative" className={boxClassName}>
          {loading ? (
            <Skeleton className="py-4" />
          ) : (
            <List
              dataSource={data}
              renderItem={(item: any) => (
                <List.Item
                  className="cursor-pointer"
                  onClick={() =>
                    navigate(`${location.pathname}?form_id=${item?.uuid}`, {
                      preventScrollReset: true,
                    })
                  }
                  key={item?.uuid}
                >
                  <div className="flex w-full items-center justify-between">
                    <div>
                      <div>{item?.title}</div>
                      <div className="flex flex-wrap">
                        <div className="flex items-center gap-[4px] text-blue-500">
                          <TbUserCircle className="text-[18px]" />
                          <span>{item?.project?.customer?.business_name}</span>
                        </div>
                        &nbsp;/&nbsp;{' '}
                        <div className="flex items-center gap-[4px] text-blue-500">
                          <IoFolderOpen />
                          <span>{item?.project?.title}</span>
                        </div>
                      </div>
                    </div>

                    <div className="whitespace-nowrap">
                      <StatusTag value={item?.status} />
                      <IoIosArrowForward className="ml-1 inline-block text-lg text-gray-600" />
                    </div>
                  </div>
                </List.Item>
              )}
            />
          )}
        </Box>
      </div>
    </>
  );
};

export default Forms;
