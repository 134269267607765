import { AxiosResponse } from 'axios';
import { call, put, StrictEffect, takeLatest } from 'redux-saga/effects';
import apiRequests from 'src/utils/api';
import apiRoutes from 'src/utils/apiRoutes';
import {
  getContacts,
  setContactsData,
  setContactsError,
  setContactsLoading,
} from './reducer';

const { get } = apiRequests;

function* getContactsSagaListener({ payload }: any) {
  try {
    yield put(setContactsLoading());
    const res: AxiosResponse = yield call(get, apiRoutes.CONTACTS, {
      ...payload,
      'filters[billing_contacts.status][]': 'active',
      'page[size]': 500,
    });

    yield put(setContactsData(res.data));
  } catch (error) {
    yield put(setContactsError(error));
  }
}
function* contactsSaga(): Generator<StrictEffect> {
  yield takeLatest(getContacts.type, getContactsSagaListener);
}

export default contactsSaga;
