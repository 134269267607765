import { FC } from 'react';
import { DownloadOutlined } from '@ant-design/icons';
import { Button, ButtonProps } from 'antd';
import { useState } from 'react';
import apiRequests from '../../utils/api';
import apiRoutes from '../../utils/apiRoutes';
import asyncErrorHandler from '../../utils/asyncErrorHandler';

interface DownloadAssetProps {
  uuid?: string;
  name?: string;
}

const DownloadAsset: FC<DownloadAssetProps> = ({ uuid, name }) => {
  const [loading, setLoading] = useState(false);

  const onClickHandle: ButtonProps['onClick'] = () => {
    if (!uuid) {
      return;
    }

    setLoading(true);

    apiRequests
      .get(`${apiRoutes.UPLOADS}/${uuid}/download`)
      .then((response) => {
        const link = document.createElement('a');
        link.href = response.data;

        if (name) {
          link.download = name;
        }

        link.click();
      })
      .catch((error) => {
        asyncErrorHandler(error);
      })
      .finally(() => setLoading(false));
  };

  return (
    <Button
      type="link"
      target="_blank"
      icon={<DownloadOutlined className="text-blue-500" />}
      loading={loading}
      className=""
      onClick={onClickHandle}
    />
  );
};

export default DownloadAsset;
