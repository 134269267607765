import { AxiosResponse } from 'axios';
import {
  call,
  put,
  select,
  StrictEffect,
  takeLatest,
} from 'redux-saga/effects';
import apiRequests from 'src/utils/api';
import apiRoutes from 'src/utils/apiRoutes';
import {
  getProjectRetainer,
  setProjectRetainerData,
  setProjectRetainerLoading,
  setProjectRetainerError,
} from './reducer';

const { get } = apiRequests;

function* getProjectRetainerSagaListener(action: any): any {
  const token = yield select((state) => state.auth.token);

  if (!token) {
    return;
  }

  try {
    yield put(setProjectRetainerLoading());
    const res: AxiosResponse = yield call(
      get,
      apiRoutes.PROJECT_RETAINER,
      undefined,
      undefined,
      true
    );

    yield put(setProjectRetainerData(res.data.data));
  } catch (error) {
    yield put(setProjectRetainerError(error));
  }
}
function* projectRetainerSaga(): Generator<StrictEffect> {
  yield takeLatest(getProjectRetainer.type, getProjectRetainerSagaListener);
}

export default projectRetainerSaga;
