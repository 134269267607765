import { FC, useState, CSSProperties } from 'react';
import { Dropdown, Menu, Tag } from 'antd';
import { getStatusColor } from 'src/utils/stringHelpers';

interface ItemsInterface {
  label: string;
  value: string;
}
interface TagDropdownProps {
  items: ItemsInterface[];
  size?: 'md' | 'lg';
  onChange?: any;
  initialValue?: string;
  onClick?: any;
  render?: any;
  trigger?: any;
  dropdown?: boolean;
  style?: CSSProperties;
}

const TagDropdown: FC<TagDropdownProps> = ({
  items,
  size = 'lg',
  onChange,
  initialValue,
  onClick,
  render,
  trigger,
  dropdown = true,
  style = {},
}) => {
  const res = items.find((el) => el.value === initialValue);
  const [active, setActive] = useState(
    (initialValue && res ? res : items[0]) as ItemsInterface
  );

  const menu = (
    <Menu>
      {items.map((el, i) => (
        <Menu.Item
          key={i}
          onClick={
            onClick
              ? () => onClick(el, setActive)
              : () => {
                  setActive(el);
                  onChange(el?.value);
                }
          }
        >
          {render ? render(el) : el.label}
        </Menu.Item>
      ))}
    </Menu>
  );

  const styles: CSSProperties =
    size === 'lg'
      ? {
          fontSize: 16,
          padding: '4px 12px',
        }
      : {};

  return (
    <Dropdown trigger={trigger} overlay={dropdown ? menu : <></>}>
      <Tag
        color={getStatusColor(active?.value)}
        style={{
          cursor: dropdown ? 'pointer' : undefined,
          ...styles,
          ...style,
        }}
      >
        {active?.label}
      </Tag>
    </Dropdown>
  );
};

export default TagDropdown;
