import {
  SetStateAction,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { Badge, Button, Dropdown, MenuProps, notification } from 'antd';
import { BellOutlined } from '@ant-design/icons';
import dayjs from 'dayjs';
import { Channel } from 'laravel-echo';
import createInfinityScrollApiContext from 'src/components/InfiniteScrollApi/createInfinityScrollApiContext';
import apiRequests from 'src/utils/api';
import apiRoutes from 'src/utils/apiRoutes';
import asyncErrorHandler from 'src/utils/asyncErrorHandler';
import {
  decreaseInboxUnreadCount,
  getInbox,
  updateRelatedUserMention,
} from 'src/store/inbox/reducer';
import { UserResponse } from 'src/types';
import Echo from 'src/websocket';
import DropdownHeader from './DropdownHeader';
import DropdownFooter, { NotificationStatus } from './DropdownFooter';
import DropdownBody from './DropdownBody';
import DropdownCard, { Notification } from './DropdownCard';
import './index.style.scss';

const BellNotificationInfinityScroll =
  createInfinityScrollApiContext<Notification>();

interface BellNotificationContainerProps {
  status: NotificationStatus;
  setStatus: (value: SetStateAction<NotificationStatus>) => void;
}

const BellNotificationContainer = ({
  status,
  setStatus,
}: BellNotificationContainerProps) => {
  const user: UserResponse = useSelector(
    (globalState: any) => globalState.auth.user
  );

  const {
    updateAllItems,
    updateItem,
    removeItem,
    removeAllItems,
    addItem,
    resetFetch,
    items,
    initialFetch,
  } = useContext(BellNotificationInfinityScroll.Context);

  const [dropdownOpen, setDropdownOpen] = useState(false);
  const [countUnread, setCountUnread] = useState(0);
  const [notificationChannel, setNotificationChannel] = useState<Channel>();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const onMarkAllRead = () => {
    apiRequests
      .post(`${apiRoutes.NOTIFICATIONS}/read-all`)
      .catch((error) => asyncErrorHandler(error));

    if (status === 'read') {
      resetFetch();
    }

    if (status === 'unread') {
      removeAllItems();
    }

    if (status === 'all') {
      updateAllItems({ read_at: dayjs().format() });
    }

    setCountUnread(0);
  };

  const onMarkRead = (target: Notification) => {
    if (target.read_at) {
      return;
    }

    if (target.data.has_mention) {
      apiRequests
        .post(`${apiRoutes.MENTIONS}/${target.data.resource_id}/read`)
        .then(() => dispatch(getInbox()))
        .catch((error) => asyncErrorHandler(error));

      dispatch(
        updateRelatedUserMention({
          user,
          uuid: target.data.resource_id,
          newValues: { read_at: dayjs().format() },
        })
      );

      dispatch(decreaseInboxUnreadCount(1));
    }

    apiRequests
      .post(`${apiRoutes.NOTIFICATIONS}/${target.id}/read`)
      .catch((error) => asyncErrorHandler(error));

    if (status === 'unread') {
      removeItem(target);
    }

    if (status === 'all') {
      updateItem(target, { read_at: dayjs().format() });
    }

    setCountUnread((old) => old - 1);
  };

  const onMarkUnread = (target: Notification) => {
    if (!target.read_at) {
      return;
    }

    apiRequests
      .post(`${apiRoutes.NOTIFICATIONS}/${target.id}/unread`)
      .catch((error) => asyncErrorHandler(error));

    if (status === 'read') {
      removeItem(target);
    }

    if (status === 'all') {
      updateItem(target, { read_at: null });
    }

    setCountUnread((old) => old + 1);
  };

  const onDelete = (target: Notification) => {
    apiRequests
      .delete(`${apiRoutes.NOTIFICATIONS}/${target.id}`)
      .catch((error) => asyncErrorHandler(error));

    removeItem(target);

    if (!target.read_at) {
      setCountUnread((old) => old - 1);
    }
  };

  useEffect(() => {
    const getCountUnread = async () => {
      try {
        const response = await apiRequests.get(
          `${apiRoutes.NOTIFICATIONS}/count`
        );

        setCountUnread(response.data.data.unread);
      } catch (error) {
        asyncErrorHandler(error);
      }
    };

    getCountUnread();
  }, []);

  useEffect(() => {
    const channelName = `users.${user?.uuid}`;

    const channel = Echo.private(channelName);

    setNotificationChannel(channel);

    return () => {
      Echo.leaveChannel(channelName);
    };
  }, [user?.uuid]);

  useEffect(() => {
    notificationChannel?.notification((notify: any) => {
      setCountUnread((old) => old + 1);

      notification.open({
        message: notify.title,
        description: notify.content,
        onClick: () => {
          const url = new URL(notify.action_url ?? '/');
          if (window.location.hostname === url.hostname) {
            navigate(url.pathname + url.search);
          } else {
            window.open(notify.action_url, '_blank');
          }
        },
        placement: 'topRight',
        icon: <BellOutlined />,
      });

      if (!initialFetch || status === 'read') {
        return;
      }

      addItem({
        id: notify.id,
        number: items?.[0]?.number + 1,
        read_at: null,
        created_at: notify.created_at,
        updated_at: notify.created_at,
        notifiable_id: user?.uuid ?? '',
        notifiable_type: 'App\\Models\\Auth\\User',
        data: {
          title: notify.title,
          sub_title: notify.sub_title,
          content: notify.content,
          action_url: notify.action_url,
          resource_id: notify.resource_id,
          has_mention: notify.has_mention,
        },
      });
    });

    return () => {
      notificationChannel?.stopListening(
        '.Illuminate\\Notifications\\Events\\BroadcastNotificationCreated'
      );
    };
  }, [
    user?.uuid,
    addItem,
    items,
    initialFetch,
    status,
    notificationChannel,
    navigate,
  ]);

  const menuItems: MenuProps['items'] = items.map((item) => {
    return {
      key: item.id,
      label: (
        <DropdownCard
          notification={item}
          onMarkAsRead={onMarkRead}
          onMarkAsUnread={onMarkUnread}
          onDelete={onDelete}
          onClick={() => setDropdownOpen(false)}
        />
      ),
    };
  });

  return (
    <Dropdown
      open={dropdownOpen}
      destroyPopupOnHide
      autoAdjustOverflow
      placement="bottomRight"
      overlayClassName="bl-dropdown-overlay"
      trigger={['click']}
      menu={{ items: menuItems }}
      dropdownRender={(menu) => (
        <>
          <div
            className="fixed left-0 top-0 h-full w-full"
            onClick={() => setDropdownOpen(false)}
          />

          <div className="bl-dropdown-content">
            <DropdownHeader onMarkAllRead={onMarkAllRead} />

            <DropdownBody
              context={BellNotificationInfinityScroll.Context}
              menuItems={menu}
            />

            <DropdownFooter
              notificationStatus={status}
              onNotificationStatusChange={setStatus}
            />
          </div>
        </>
      )}
    >
      <Badge count={countUnread} offset={[-8, 7]}>
        <Button
          type="link"
          icon={<BellOutlined style={{ color: 'white', fontSize: 24 }} />}
          onClick={() => setDropdownOpen(true)}
        />
      </Badge>
    </Dropdown>
  );
};

const BellNotification = () => {
  const [status, setStatus] = useState<NotificationStatus>('all');
  const params = useMemo(() => {
    if (status !== 'all') {
      return { filter: status };
    }

    return undefined;
  }, [status]);

  return (
    <BellNotificationInfinityScroll.Provider
      url={apiRoutes.NOTIFICATIONS}
      params={params}
      sortKey="number"
    >
      <BellNotificationContainer status={status} setStatus={setStatus} />
    </BellNotificationInfinityScroll.Provider>
  );
};

export default BellNotification;
