export function debounceAsync<
  T,
  Callback extends (...args: any[]) => Promise<T> = (
    ...args: any[]
  ) => Promise<T>
>(
  callback: Callback,
  wait: number
): (...args: Parameters<Callback>) => Promise<T> {
  let timeoutId: ReturnType<typeof setTimeout> | null = null;

  return (...args: any[]) => {
    if (timeoutId) {
      clearTimeout(timeoutId);
    }

    return new Promise<T>((resolve) => {
      const timeoutPromise = new Promise<void>((timeoutResolve) => {
        timeoutId = setTimeout(timeoutResolve, wait);
      });

      timeoutPromise.then(async () => {
        resolve(await callback(...args));
      });
    });
  };
}
