import { FC, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { AxiosResponse } from 'axios';
import { Button, Form, Input, message, Skeleton } from 'antd';
import { FaCheckCircle } from 'react-icons/fa';
import Box from 'src/components/Box';
import RichTextEditor from 'src/components/RichTextEditor';
import { UploadFileApi, UserResponse } from 'src/types';
import apiRequests from 'src/utils/api';
import apiRoutes from 'src/utils/apiRoutes';
import asyncErrorHandler from 'src/utils/asyncErrorHandler';
import { convertMinToHrsMin } from 'src/utils/stringHelpers';
import { RootState } from 'src/store';
import { updateAuthData } from 'src/store/auth/reducer';
import { setProjectRetainerData } from 'src/store/projectRetainer/reducer';

const CompanyProfile: FC = () => {
  const user: UserResponse = useSelector(
    (globalState: any) => globalState.auth.user
  );
  const dispatch = useDispatch();

  const [noteImages, setNoteImages] = useState<UploadFileApi[]>([]);
  const [submitLoading, setSubmitLoading] = useState(false);

  const projectRetainer = useSelector(
    (globalState: RootState) => globalState.projectRetainer
  );

  const customer = user.contact.customer;

  const formInitialState = {
    business_name: customer.business_name,
    billing_email: customer.billing_email,
    phone: customer.phone,
    website: customer.website,
    address_line1: customer.address_line1,
    city: customer.city,
    state: customer.state,
    zip: customer.zip,
    notes: projectRetainer.data?.sales_notes,
  };

  const submitHandler = async (values: any) => {
    setSubmitLoading(true);

    try {
      const { notes, business_name, billing_email, ...customerValues } = values;

      const uploads = notes
        ? noteImages.filter((x) => x.url && notes.includes(`src="${x.url}"`))
        : [];

      const promises: Promise<AxiosResponse>[] = [];

      promises.push(
        apiRequests.put(
          `${apiRoutes.CUSTOMERS}/${customer.uuid}`,
          customerValues
        )
      );

      if (projectRetainer.data) {
        promises.push(
          apiRequests.put(
            `${apiRoutes.PROJECTS}/${projectRetainer.data.uuid}`,
            {
              sales_notes: notes,
              uploads: uploads.map((x) => x.uid),
            }
          )
        );
      }

      const [customerResponse, projectResponse] = await Promise.all(promises);

      if (projectResponse) {
        if (notes) {
          setNoteImages((old) =>
            old.filter((x) => x.url && !uploads.includes(x))
          );
        }

        dispatch(setProjectRetainerData(projectResponse.data.data));
      }

      dispatch(
        updateAuthData({
          ...user,
          contact: {
            ...user.contact,
            customer: {
              ...user.contact.customer,
              ...customerResponse.data.data,
            },
          },
        })
      );

      message.success('Company profile updated.');
    } catch (error) {
      asyncErrorHandler(error);
    } finally {
      setSubmitLoading(false);
    }
  };

  return (
    <Box>
      {projectRetainer.loading && !projectRetainer.data ? (
        <Skeleton />
      ) : (
        <div className="flex flex-wrap gap-6 pt-2">
          <div className="w-full flex-none lg:flex-1">
            <Form
              className="default-form form-label-no-bold"
              layout="vertical"
              initialValues={formInitialState}
              onFinish={submitHandler}
            >
              <div className="mb-4 flex justify-between gap-2">
                <h2 className="text-2xl font-bold leading-none text-blue-700">
                  My account
                </h2>

                <Button
                  htmlType="submit"
                  type="primary"
                  loading={submitLoading}
                >
                  Save changes
                </Button>
              </div>

              <Form.Item label="Company" name="business_name">
                <Input disabled />
              </Form.Item>

              <div className="flex flex-wrap gap-x-2">
                <Form.Item
                  label="Email"
                  name="billing_email"
                  className="flex-auto"
                >
                  <Input disabled />
                </Form.Item>

                <Form.Item label="Phone" name="phone" className="flex-auto">
                  <Input />
                </Form.Item>
              </div>

              <Form.Item label="Website" name="website">
                <Input />
              </Form.Item>

              <Form.Item label="Address" name="address_line1">
                <Input />
              </Form.Item>

              <div className="flex flex-wrap gap-x-2">
                <Form.Item
                  label="City"
                  name="city"
                  className="flex-auto sm:flex-1"
                >
                  <Input />
                </Form.Item>

                <Form.Item
                  label="State"
                  name="state"
                  className="flex-auto sm:flex-1"
                >
                  <Input />
                </Form.Item>

                <Form.Item
                  label="Zip code"
                  name="zip"
                  className="flex-auto sm:flex-1"
                >
                  <Input />
                </Form.Item>
              </div>

              {projectRetainer.data ? (
                <>
                  <div className="ant-form-item-label ant-col">
                    <label>Notes</label>
                  </div>

                  <Form.Item name="notes" noStyle>
                    <RichTextEditor
                      className="quill-scroll-small mb-6"
                      includeToolbar
                      onImageUploader={(upload) => {
                        setNoteImages((prev) => [...prev, upload]);
                      }}
                    />
                  </Form.Item>
                </>
              ) : undefined}
            </Form>
          </div>

          {customer.subscription.time_budget ? (
            <div className="w-full flex-1 space-y-4 pb-8 lg:w-5/12">
              <div className="flex justify-between gap-2 whitespace-nowrap pb-5">
                <h2 className="text-2xl font-bold leading-none text-blue-700">
                  My plan
                </h2>

                <a
                  href="https://calendar.app.google/L23Mficz26s8NiPS9"
                  target="_blank"
                  rel="noreferrer"
                >
                  <Button type="primary">Upgrade my plan</Button>
                </a>
              </div>

              <div className="flex flex-col items-center rounded border border-blue-500 bg-blue-100 p-3 font-bold text-blue-700">
                <div className="text-xs uppercase">
                  {customer.subscription.name}
                </div>

                <div className="text-center text-3xl">
                  {convertMinToHrsMin(customer.subscription.time_budget, false)}{' '}
                  hours/mo
                </div>
              </div>

              <div className="text-center font-bold">Services includes</div>

              <div className="space-y-2" style={{ wordBreak: 'break-word' }}>
                {customer.subscription.services.map((item: any) => (
                  <div className="flex items-center gap-2" key={item.name}>
                    <FaCheckCircle className="flex-none text-blue-700" />
                    <span>{item.name}</span>
                  </div>
                ))}
              </div>
            </div>
          ) : undefined}
        </div>
      )}
    </Box>
  );
};

export default CompanyProfile;
