import { createSlice } from '@reduxjs/toolkit';
import { ApiResponse, InboxRelatedUser, TaskResponse } from 'src/types';

export interface TasksStateType {
  loading: boolean;
  data: ApiResponse<TaskResponse> | null;
  error: null | string;
}

const initialState: TasksStateType = {
  loading: false,
  data: null,
  error: null,
};

export const pendingTasksSlice = createSlice({
  name: 'pendingTasks',
  initialState,
  reducers: {
    getPendingTasks: () => {},
    setPendingTasksLoading: (state) => ({ ...state, loading: true }),
    setPendingTasksData: (state, action) => ({
      ...state,
      loading: false,
      data: action.payload,
    }),
    clearPendingTasksData: () => initialState,
    setPendingTasksError: (state, action) => ({
      ...state,
      loading: false,
      error: action.payload,
    }),
    updatePendingTaskCommentRelatedUserMention: (
      state,
      {
        payload,
      }: {
        payload: {
          uuid: string;
          newValues: Partial<InboxRelatedUser>;
          user: any;
        };
        type: string;
      }
    ) =>
      state.data
        ? {
            ...state,
            data: {
              ...state.data,
              data: state.data.data.map((item) =>
                item.uuid === payload.uuid && item.comment_latest
                  ? {
                      ...item,
                      comment_latest: {
                        ...item.comment_latest,
                        related_users: item.comment_latest.related_users.map(
                          (relatedUser) =>
                            relatedUser.uuid !== payload.user.uuid
                              ? relatedUser
                              : { ...relatedUser, ...payload.newValues }
                        ),
                      },
                    }
                  : item
              ),
            },
          }
        : state,
  },
});

export const {
  setPendingTasksData,
  setPendingTasksError,
  setPendingTasksLoading,
  getPendingTasks,
  updatePendingTaskCommentRelatedUserMention,
} = pendingTasksSlice.actions;

export default pendingTasksSlice.reducer;
