import { Form, Input, Modal } from 'antd';
import { FC, useState } from 'react';
import { AiOutlineMail } from 'react-icons/ai';
import { useSelector } from 'react-redux';
import apiRequests from 'src/utils/api';
import apiRoutes from 'src/utils/apiRoutes';
import asyncErrorHandler from 'src/utils/asyncErrorHandler';
import { UserResponse } from 'src/types';

interface InviteModalProps {
  visible: boolean;
  onInviteSent?: () => void;
  onClose: () => void;
}

const InviteModal: FC<InviteModalProps> = ({
  visible,
  onInviteSent,
  onClose,
}) => {
  const user: UserResponse = useSelector(
    (globalState: any) => globalState.auth.user
  );
  const [loading, setLoading] = useState(false);
  const [form] = Form.useForm();

  const showSentInviteModal = (email: string) => {
    Modal.success({
      title: 'Invite sent successfully!',
      content: (
        <p>
          The invitation was successfully sent to <b>{email}</b> email.
        </p>
      ),
      footer: null,
      maskClosable: true,
    });
  };

  const submit = async () => {
    setLoading(true);

    try {
      const data = await form.validateFields();

      try {
        await apiRequests.post(`${apiRoutes.CUSTOMER_INVITE}`, {
          ...data,
          customer_id: user.contact.customer.uuid,
          advertising: false,
          creative_package: false,
          leadsie_manage_link: false,
          leadsie_social_link: false,
          login_register: true,
          schedule_call_link: false,
          social_media: false,
          video_production: false,
          website: false,
          welcome_kit: false,
        });

        onClose();
        onInviteSent?.();

        showSentInviteModal(data.email);
      } catch (error) {
        asyncErrorHandler(error);
      }
    } catch (error) {
    } finally {
      setLoading(false);
    }
  };

  return (
    <Modal
      destroyOnClose
      confirmLoading={loading}
      title="Send an invite"
      open={visible}
      onCancel={onClose}
      onOk={submit}
      okText="Invite"
      afterClose={() => {
        form.resetFields();
      }}
    >
      <Form form={form} layout="vertical" className="default-form" size="large">
        <Form.Item
          label="E-mail"
          name="email"
          rules={[
            {
              required: true,
              type: 'email',
              message: 'Please input a e-mail!',
            },
          ]}
        >
          <Input
            prefix={<AiOutlineMail />}
            placeholder="E-mail to send the invite"
          />
        </Form.Item>
      </Form>
    </Modal>
  );
};

export default InviteModal;
