import { FC, useState } from 'react';
import useBreakpoint from 'antd/es/grid/hooks/useBreakpoint';
import { FaAngleLeft, FaAngleRight } from 'react-icons/fa6';
import { Breakpoint } from 'antd';
import Lightbox, { SlideImage } from 'yet-another-react-lightbox';
import Slideshow from 'yet-another-react-lightbox/plugins/slideshow';
import Inline from 'yet-another-react-lightbox/plugins/inline';
import 'yet-another-react-lightbox/styles.css';
import ad1Pc from 'src/assets/images/ad1-pc.png';
import ad1Tablet from 'src/assets/images/ad1-tablet.png';
import ad1Mobile from 'src/assets/images/ad1-mobile.png';
import ad2Pc from 'src/assets/images/ad2-pc.png';
import ad2Tablet from 'src/assets/images/ad2-tablet.png';
import ad2Mobile from 'src/assets/images/ad2-mobile.png';

const pcImages: SlideImage[] = [
  { src: ad1Pc, width: 420, height: 600 },
  { src: ad2Pc, width: 420, height: 600 },
];

const tableImages: SlideImage[] = [
  { src: ad1Tablet, width: 1620, height: 180 },
  { src: ad2Tablet, width: 1620, height: 180 },
];

const mobileImages: SlideImage[] = [
  { src: ad1Mobile, width: 720, height: 200 },
  { src: ad2Mobile, width: 720, height: 200 },
];

const imageLinks = [
  'https://www.savageglobalmarketing.com/subscriptions/',
  'https://www.savageglobalmarketing.com/subscriptions/',
];

const getListImages = (
  breakpoints: Partial<Record<Breakpoint, boolean>>
): { ratio: string; slides: SlideImage[] } => {
  if (breakpoints.xl) return { ratio: '0.7', slides: pcImages };

  if (breakpoints.md) return { ratio: '9', slides: tableImages };

  return { ratio: '3.6', slides: mobileImages };
};

interface AdCarouselProps {
  containerClassName?: string;
}

const AdCarousel: FC<AdCarouselProps> = ({ containerClassName }) => {
  const [index, setIndex] = useState(0);
  const breakpoints = useBreakpoint(true);

  const { slides, ratio } = getListImages(breakpoints);

  return (
    <div className={containerClassName}>
      <Lightbox
        index={index}
        slides={slides}
        plugins={[Inline, Slideshow]}
        carousel={{
          padding: 0,
          spacing: 0,
          imageFit: 'cover',
        }}
        slideshow={{ autoplay: true }}
        render={{
          buttonSlideshow: () => null,
          buttonPrev: () => null,
          buttonNext: () => null,
        }}
        inline={{
          style: {
            width: '100%',
            aspectRatio: ratio,
          },
        }}
        styles={{ slide: { cursor: 'pointer' } }}
        on={{
          view: ({ index: currentIndex }) => setIndex(currentIndex),
          click: ({ index }) => {
            window.open(imageLinks[index], '_blank');
          },
        }}
      />

      <div className="flex items-center justify-center gap-2 pt-2">
        <button
          className="cursor-pointer text-gray-700 hover:text-black"
          onClick={() =>
            setIndex((old) => (old < 1 ? slides.length - 1 : old - 1))
          }
        >
          <FaAngleLeft />
        </button>

        {slides.map((_, idx) => (
          <div
            key={idx}
            role="button"
            className={`h-2 w-2 cursor-pointer rounded-full transition-colors ${
              idx !== index ? 'bg-gray-300' : 'bg-gray-600'
            }`}
            onClick={() => setIndex(idx)}
          ></div>
        ))}

        <button
          className="cursor-pointer text-gray-700 hover:text-black"
          onClick={() =>
            setIndex((old) => (old >= slides.length - 1 ? 0 : old + 1))
          }
        >
          <FaAngleRight />
        </button>
      </div>
    </div>
  );
};

export default AdCarousel;
