import { AxiosResponse } from 'axios';
import { call, put, StrictEffect, takeLatest } from 'redux-saga/effects';
import apiRequests from 'src/utils/api';
import apiRoutes from 'src/utils/apiRoutes';

import {
  getProjectTasks,
  setProjectTasksData,
  setProjectTasksError,
  setProjectTasksLoading,
} from './reducer';
import asyncErrorHandler from 'src/utils/asyncErrorHandler';
import { sortTaskByDueDate, sortTasksByStatus } from 'src/utils/arrayHelpers';

const { get } = apiRequests;

function* getProjectTasksSagaListener(action: any) {
  try {
    yield put(setProjectTasksLoading());

    const res: AxiosResponse = yield call(
      get,
      apiRoutes.PROJECT_TASKS,
      action.payload
    );

    yield put(
      setProjectTasksData({
        ...res.data,
        data: sortTasksByStatus(sortTaskByDueDate(res.data.data)),
      })
    );
  } catch (error) {
    yield put(setProjectTasksError(error));
    asyncErrorHandler(error);
  }
}

function* projectTasksSaga(): Generator<StrictEffect> {
  yield takeLatest(getProjectTasks.type, getProjectTasksSagaListener);
}

export default projectTasksSaga;
