import { AxiosResponse } from 'axios';
import {
  call,
  put,
  select,
  StrictEffect,
  takeLatest,
} from 'redux-saga/effects';
import apiRequests from 'src/utils/api';
import apiRoutes from 'src/utils/apiRoutes';
import asyncErrorHandler from 'src/utils/asyncErrorHandler';
import { sortTaskByDueDate, sortTasksByStatus } from 'src/utils/arrayHelpers';
import {
  getDashboardTasks,
  setDashboardTasksData,
  setDashboardTasksError,
  setDashboardTasksLoading,
} from './reducer';
import { RootState } from '../index';

const { get } = apiRequests;

function* getDashboardTasksSagaListener(action: any) {
  try {
    yield put(setDashboardTasksLoading());

    const showAll: boolean = yield select(
      (globalStore: RootState) => globalStore.dashboardTasks.showCompleted
    );

    const res: AxiosResponse = yield call(get, apiRoutes.PROJECT_TASKS, {
      'filters[task_status]': [
        'todo',
        'in_progress',
        'waiting_approval',
        'working_on_now',
        'pending',
        'approved',
        'needs_attention',
        ...(showAll ? ['completed'] : []),
      ],
      ...(action.payload ?? {}),
    });

    yield put(
      setDashboardTasksData({
        ...res.data,
        data: sortTasksByStatus(sortTaskByDueDate(res.data.data)),
      })
    );
  } catch (error) {
    yield put(setDashboardTasksError(error));
    asyncErrorHandler(error);
  }
}

function* dashboardTasksSaga(): Generator<StrictEffect> {
  yield takeLatest(getDashboardTasks.type, getDashboardTasksSagaListener);
}

export default dashboardTasksSaga;
