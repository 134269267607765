import { configureStore, combineReducers, AnyAction } from '@reduxjs/toolkit';
import reducers from './combinedReducer';
import combinedSaga from './combinedSaga';

import createSagaMiddleware from 'redux-saga';

const sagaMiddleware = createSagaMiddleware();

const combinedReducer = combineReducers(reducers);

const rootReducer = (state: any, action: AnyAction) => {
  if (action.type === 'store/reset') {
    state = {
      auth: state.auth,
    };
  }
  return combinedReducer(state, action);
};

const store = configureStore({
  reducer: rootReducer,
  middleware: [sagaMiddleware],
});

sagaMiddleware.run(combinedSaga);

export type RootState = ReturnType<typeof store.getState>;

export default store;
