import { FC, ReactNode } from 'react';
import Maintenance from 'src/screens/maintenance';
import ENVIRONMENT from 'src/utils/environments';

interface CheckMaintenanceProps {
  children: ReactNode;
  allowInMaintenance?: boolean;
  user: any;
}

const CheckMaintenance: FC<CheckMaintenanceProps> = ({
  user,
  allowInMaintenance = false,
  children,
}) => {
  const isInMaintenance = ENVIRONMENT.MAINTENANCE_MODE;

  const whiteList = [
    'maxcelos@outlook.com',
    'testclient@email.com',
    'sanchexm@gmail.com',
    'marcelo.bsilva@outlook.com.br',
    'jsmithxm@stinc.com',
    'sanchexm@hotmail.com',
    'mikegreenxm@gmail.com',
  ];

  if (
    !allowInMaintenance &&
    isInMaintenance &&
    (!user || !whiteList.includes(user.email))
  ) {
    return <Maintenance />;
  }

  return <>{children}</>;
};

export default CheckMaintenance;
