import React from 'react';
import { Link } from 'react-router-dom';
import { Result } from 'antd';

const ErrorPage: React.FC = () => {
  return (
    <div className="h-screen w-full" id="error-page">
      <Result
        status="404"
        title="404"
        subTitle="Sorry, the page you visited does not exist."
        extra={
          <Link
            to="/dashboard"
            className="rounded-full border border-[#0044cc] px-4 py-2 hover:border-blue-400"
          >
            Back Home
          </Link>
        }
      >
        {/* {error} */}
      </Result>
    </div>
  );
};

export default ErrorPage;
