import { message } from 'antd';
import axios, { AxiosError } from 'axios';
import { getToken, logoutUser } from './auth';
import apiRoutes from './apiRoutes';

const headers: any = {};
headers['Content-Type'] = 'application/json';
headers.withCredentials = true;
headers.Accept = 'application/json';
headers['Access-Control-Allow-Origin'] = '*';

axios.defaults.withCredentials = true;

const setHeaderAuth = (token: string | null) => {
  if (token) {
    headers.Authorization = `Bearer ${token}`;
  } else {
    delete headers.Authorization;
  }
};

setHeaderAuth(getToken());

const handleAuthError = (error: AxiosError) => {
  if (error?.response?.status === 401) {
    message.error('Your not authorized.');
    logoutUser();
  } else if (error?.response?.status === 503) {
    message.error('Site in maintenance.');
  }

  return null;
};

const getApi = (
  url: string,
  params = {},
  header = {},
  ignoreError: boolean = false
) => {
  return axios
    .get(url, { params, headers: { ...headers, ...header } })
    .then((response) => response)
    .catch((e) => {
      if (!ignoreError) {
        handleAuthError(e);
      }

      throw e;
    });
};

const postApi = (
  url: string,
  data = {},
  header = {},
  abortController?: AbortController
) => {
  return axios
    .post(url, data, { headers: { ...headers, ...header } })
    .then((response) => response)
    .catch((e) => {
      handleAuthError(e);
      throw e;
    });
};

const deleteApi = (url: string, data = {}, params = {}, header = {}) => {
  return axios
    .delete(url, { data, params, headers: { ...headers, ...header } })
    .then((response) => response)
    .catch((e) => {
      handleAuthError(e);
      throw e;
    });
};

const patchApi = (url: string, data = {}, header = {}) => {
  return axios
    .patch(url, data, { headers: { ...headers, ...header } })
    .then((response) => response)
    .catch((e) => {
      handleAuthError(e);
      throw e;
    });
};

const putApi = (url: string, data = {}, header = {}) => {
  return axios
    .put(url, data, { headers: { ...headers, ...header } })
    .then((response) => response)
    .catch((e) => {
      handleAuthError(e);
      throw e;
    });
};

const createPreSigned = async ({
  file,
  type = 'attachment',
  abortController,
}: {
  file: File;
  type?: 'attachment' | 'in-message';
  abortController?: AbortController;
}): Promise<{ url: string; uuid: string }> => {
  const fmData = {
    extension: file.name?.split('.').reverse()[0],
    size: file.size,
    name: file.name,
    type,
  };
  const res = await apiRequests.post(
    `${apiRoutes.CREATE_PRE_SIGNED}`,
    fmData,
    {},
    abortController
  );
  return res.data;
};

const uploadFile = async ({
  file,
  type = 'attachment',
  abortController,
}: {
  file: File;
  type?: 'attachment' | 'in-message';
  abortController?: AbortController;
}) => {
  const preSignedRes = await createPreSigned({ file, type, abortController });

  await axios({
    method: 'PUT',
    data: file,
    url: preSignedRes.url,
    withCredentials: false,
    headers: {
      'Content-Type': file.type,
    },
    signal: abortController?.signal,
  });

  const url = new URL(preSignedRes.url);

  preSignedRes.url = url.origin + url.pathname;

  return { ...preSignedRes, stated: false };
};

const apiRequests = {
  get: getApi,
  post: postApi,
  delete: deleteApi,
  patch: patchApi,
  put: putApi,
  uploadFile,
};

export { setHeaderAuth };

export default apiRequests;
