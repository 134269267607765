import { FC } from 'react';
import { Navigate, useParams } from 'react-router-dom';

const SubmitForm: FC = () => {
  const { uuid } = useParams();

  return <Navigate to={`/pending/?form_id=${uuid}`} replace />;
};

export default SubmitForm;
