import { createSlice } from '@reduxjs/toolkit';
import { ApiResponse, InboxRelatedUser, InboxResponse } from 'src/types';

export interface InboxStateType {
  loading: boolean;
  data: ApiResponse<InboxResponse> | null;
  error: null | string;
  count: null | number;
}

const initialState: InboxStateType = {
  loading: false,
  data: null,
  error: null,
  count: null,
};

export const inboxSlice = createSlice({
  name: 'inbox',
  initialState,
  reducers: {
    getInbox: () => {},
    setInboxLoading: (state) => ({ ...state, loading: true }),
    setInboxData: (state, action) => ({
      ...state,
      loading: false,
      data: action.payload,
    }),
    updateRelatedUserMention: (
      state,
      {
        payload,
      }: {
        payload: {
          uuid: string;
          newValues: Partial<InboxRelatedUser>;
          user: any;
        };
        type: string;
      }
    ) =>
      state.data
        ? {
            ...state,
            data: {
              ...state.data,
              data: state.data.data.map((item) =>
                item.uuid === payload.uuid
                  ? {
                      ...item,
                      related_users: item.related_users.map((relatedUser) =>
                        relatedUser.uuid !== payload.user.uuid
                          ? relatedUser
                          : { ...relatedUser, ...payload.newValues }
                      ),
                    }
                  : item
              ),
            },
          }
        : state,
    setInboxCount: (state, action: { payload: number; type: string }) => ({
      ...state,
      count: action.payload,
    }),
    increaseInboxUnreadCount: (
      state,
      action: { payload: number; type: string }
    ) => ({
      ...state,
      count: state.count ? state.count + action.payload : state.count,
    }),
    decreaseInboxUnreadCount: (
      state,
      action: { payload: number; type: string }
    ) => ({
      ...state,
      count: state.count ? state.count - action.payload : state.count,
    }),
    clearInboxData: () => initialState,
    setInboxError: (state, action) => ({
      ...state,
      loading: false,
      error: action.payload,
    }),
  },
});

export const {
  setInboxData,
  setInboxError,
  setInboxLoading,
  setInboxCount,
  getInbox,
  updateRelatedUserMention,
  decreaseInboxUnreadCount,
} = inboxSlice.actions;

export default inboxSlice.reducer;
