import { Tag } from 'antd';
import { FC } from 'react';
import {
  convertSnakeCaseToTitleCase,
  getStatusColor,
} from 'src/utils/stringHelpers';

interface StatusTagProps {
  value: string | any;
}

const StatusTag: FC<StatusTagProps> = ({ value }) => {
  return (
    <Tag color={getStatusColor(value)} bordered={false}>
      {convertSnakeCaseToTitleCase(value)}
    </Tag>
  );
};

export default StatusTag;
