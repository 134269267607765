import { FC, useEffect } from 'react';
import { Skeleton } from 'antd';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import dayjs from 'dayjs';
import Forms from 'src/components/Forms';
import Task from 'src/components/Task';
import {
  getPendingTasks,
  updatePendingTaskCommentRelatedUserMention,
} from 'src/store/pendingTasks/reducer';
import { getPendingForms } from 'src/store/pendingForms/reducer';
import AlertPendingCard from 'src/components/AlertPendingCard';
import { RootState } from 'src/store';

const Pending: FC = () => {
  const pendingTasks = useSelector(
    (globalState: RootState) => globalState.pendingTasks
  );
  const pendingForms = useSelector(
    (globalState: RootState) => globalState.pendingForms
  );

  const navigate = useNavigate();

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getPendingTasks());
    dispatch(getPendingForms());
  }, [dispatch]);

  useEffect(() => {
    if (
      !pendingTasks.loading &&
      pendingTasks.data &&
      !pendingTasks.data.data.length &&
      !pendingForms.loading &&
      pendingForms.data &&
      !pendingForms.data.data.length
    ) {
      navigate('/dashboard', { replace: true });
    }
  }, [
    pendingTasks.loading,
    pendingTasks.data,
    pendingForms.loading,
    pendingForms.data,
    navigate,
  ]);

  const showLoading =
    (pendingForms.loading && !pendingForms.data) ||
    (pendingTasks.loading && !pendingTasks.data);

  return (
    <>
      {pendingTasks.data?.data?.length || pendingForms.data?.data?.length ? (
        <AlertPendingCard />
      ) : undefined}

      <div className="mt-6 flex flex-wrap gap-8">
        {!showLoading && pendingTasks.data?.data?.length ? (
          <Task
            className="flex-1 basis-full 2xl:basis-0"
            data={pendingTasks.data.data}
            layoutBreakpoint={
              pendingForms.data?.data?.length ? 'none' : undefined
            }
            onMarkAsRead={(uuid, user) => {
              dispatch(
                updatePendingTaskCommentRelatedUserMention({
                  user,
                  uuid,
                  newValues: { read_at: dayjs().format() },
                })
              );
            }}
          />
        ) : undefined}

        {showLoading ? (
          <>
            <Skeleton className="flex-1 basis-full pt-2 lg:basis-0" />
            <Skeleton className="flex-1 basis-full pt-2 lg:basis-0" />
          </>
        ) : undefined}

        {!showLoading && pendingForms.data?.data?.length ? (
          <Forms
            className="flex-1 basis-full 2xl:basis-0"
            data={pendingForms.data.data}
          />
        ) : undefined}
      </div>
    </>
  );
};

export default Pending;
