import { FC, useEffect, useState } from 'react';
import {
  Button,
  Card,
  Checkbox,
  Col,
  Form,
  Input,
  message,
  Row,
  Space,
  Spin,
} from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import SmsSignUpModal from 'src/components/SmsSignUpModal';
import { updateAuthData } from 'src/store/auth/reducer';
import apiRequests from 'src/utils/api';
import apiRoutes from 'src/utils/apiRoutes';
import asyncErrorHandler from 'src/utils/asyncErrorHandler';
import { UserResponse } from 'src/types';
import AxiosErrorResult from 'src/components/AxiosErrorResult';

const { Password } = Input;

const Profile: FC = () => {
  const user: UserResponse = useSelector(
    (globalState: any) => globalState.auth.user
  );
  const [fetching, setFetching] = useState(true);
  const [saving, setSaving] = useState(false);
  const [error, setError] = useState<any>();
  const [smsModalOpen, setSmsModalOpen] = useState(false);
  const [hasEmailNotification, setHasEmailNotification] = useState(false);

  const dispatch = useDispatch();

  const [form] = Form.useForm();

  const onSubmitHandler = async (ignoreSms = false) => {
    setSaving(true);

    try {
      const values = await form.validateFields();

      if (
        !ignoreSms &&
        values.preferences?.sms_notifications &&
        user.preferences.sms_notifications !== '1'
      ) {
        setSmsModalOpen(true);
        return;
      }

      if (
        values.preferences?.push_notifications &&
        'Notification' in window &&
        Notification.permission === 'denied'
      ) {
        message.warning(
          'Notification permission denied, please enable the notification permission on this browser'
        );
      }

      await apiRequests.put(
        `${apiRoutes.CONTACTS}/${user.contact.uuid}`,
        values
      );

      dispatch(
        updateAuthData({
          ...user,
          contact: {
            ...user.contact,
            ...values,
          },
          preferences: Object.keys(values.preferences).reduce((acc, value) => {
            return { ...acc, [value]: values.preferences[value] ? '1' : '0' };
          }, {}),
        })
      );

      message.success('Profile updated');
    } catch (error) {
      asyncErrorHandler(error);
      setError(error);
    } finally {
      setSaving(false);
    }
  };

  useEffect(() => {
    const fetchData = async () => {
      setFetching(true);

      try {
        const res = await apiRequests.get(
          `${apiRoutes.CONTACTS}/${user.contact.uuid}`
        );
        const data = res.data.data;

        data.preferences = {};

        Object.keys(data.user.preferences).forEach((key) => {
          if (data.user.preferences[key] === null) {
            data.preferences[key] = null;
          } else {
            data.preferences[key] = data.user.preferences[key] === '1';
          }
        });

        setHasEmailNotification(data.preferences.email_notifications);

        form.setFieldsValue({
          ...data,
          type: data.type === 'regular_user' ? 'regular_user' : 'contact',
        });
      } catch (error) {
        asyncErrorHandler(error);
      } finally {
        setFetching(false);
      }
    };

    fetchData();
  }, [form, user.contact.uuid]);

  if (!fetching && error) {
    return <AxiosErrorResult error={error} />;
  }

  return (
    <Space size={12} direction="vertical" className="w-full">
      {smsModalOpen && (
        <SmsSignUpModal
          user={{
            ...user,
            contact: form.getFieldsValue(),
          }}
          actionApi={false}
          onSubmit={(values) => {
            setSmsModalOpen(false);
            form.setFieldsValue(values);
            onSubmitHandler(true);
          }}
          onClose={() => {
            setSmsModalOpen(false);
            form.setFieldsValue({ preferences: { sms_notifications: false } });
            onSubmitHandler(true);
          }}
        />
      )}

      <Card
        title="Personal Information"
        loading={fetching}
        extra={
          <Button
            disabled={saving || fetching}
            onClick={() => onSubmitHandler()}
            type="primary"
          >
            Save
          </Button>
        }
      >
        <Spin spinning={saving}>
          <Form
            form={form}
            colon={false}
            labelAlign="left"
            labelCol={{ span: 6 }}
            wrapperCol={{ span: 18 }}
          >
            <Row gutter={[16, 0]}>
              <Col xs={24} lg={12}>
                <Form.Item
                  label="Full name"
                  name="name"
                  rules={[
                    {
                      required: true,
                      message: 'This field is required',
                    },
                  ]}
                >
                  <Input />
                </Form.Item>

                <Form.Item
                  label="Email"
                  name="email"
                  rules={[
                    {
                      required: true,
                      message: 'This field is required',
                    },
                  ]}
                >
                  <Input type="email" />
                </Form.Item>
              </Col>

              <Col xs={24} lg={12}>
                <Form.Item label="Phone" name="phone">
                  <Input />
                </Form.Item>

                <Form.Item label="Address" name="address_line1">
                  <Input />
                </Form.Item>
              </Col>

              <Col xs={24} lg={12}>
                <Form.Item label="City" name="city">
                  <Input />
                </Form.Item>

                <Form.Item label="State" name="state">
                  <Input />
                </Form.Item>
              </Col>

              <Col xs={24} lg={12}>
                <Form.Item label="Zip code" name="zip">
                  <Input />
                </Form.Item>

                <Form.Item label="Country" name="country">
                  <Input />
                </Form.Item>
              </Col>

              <Col xs={24} lg={12}>
                <Form.Item label="Job Title" name="job_title">
                  <Input />
                </Form.Item>
              </Col>
            </Row>
          </Form>
        </Spin>
      </Card>

      <Card title="Update password" loading={fetching}>
        <Spin spinning={saving}>
          <Form
            autoComplete="off"
            colon={false}
            form={form}
            labelAlign="left"
            labelCol={{ span: 6 }}
            wrapperCol={{ span: 18 }}
          >
            <Form.Item label="Password" name="password">
              <Password placeholder="********" />
            </Form.Item>

            <Form.Item label="Confirm Password" name="confirm_password">
              <Password placeholder="********" />
            </Form.Item>
          </Form>
        </Spin>
      </Card>

      <Card title="Notification Channels" loading={fetching}>
        <Spin spinning={saving}>
          <Form
            autoComplete="off"
            colon={false}
            form={form}
            labelAlign="left"
            labelCol={{ span: 6 }}
            wrapperCol={{ span: 18 }}
            layout="inline"
          >
            <Form.Item
              name={['preferences', 'email_notifications']}
              valuePropName="checked"
            >
              <Checkbox
                onChange={(event) =>
                  setHasEmailNotification(event.target.checked)
                }
              >
                Email
              </Checkbox>
            </Form.Item>

            <Form.Item
              name={['preferences', 'sms_notifications']}
              valuePropName="checked"
            >
              <Checkbox>SMS</Checkbox>
            </Form.Item>

            <Form.Item
              name={['preferences', 'push_notifications']}
              valuePropName="checked"
            >
              <Checkbox>Push</Checkbox>
            </Form.Item>
          </Form>
        </Spin>
      </Card>

      {hasEmailNotification && !fetching && (
        <Card title="Email notification settings">
          <Spin spinning={saving}>
            <Form
              autoComplete="off"
              colon={false}
              form={form}
              labelAlign="left"
              labelCol={{ span: 6 }}
              wrapperCol={{ span: 18 }}
            >
              <Form.Item
                name={['preferences', 'email_tasks_notification']}
                className="!mb-0"
                valuePropName="checked"
              >
                <Checkbox style={{ whiteSpace: 'nowrap' }}>
                  Task and project updates
                </Checkbox>
              </Form.Item>

              <Form.Item
                name={['preferences', 'email_mentions_notification']}
                valuePropName="checked"
                className="!mb-0"
              >
                <Checkbox style={{ whiteSpace: 'nowrap' }}>
                  Comment mentions
                </Checkbox>
              </Form.Item>

              <Form.Item
                name={['preferences', 'email_daily_reminder_notification']}
                valuePropName="checked"
                className="!mb-0"
              >
                <Checkbox style={{ whiteSpace: 'nowrap' }}>
                  Daily updates (morning)
                </Checkbox>
              </Form.Item>

              <Form.Item
                name={['preferences', 'email_daily_digest_notification']}
                valuePropName="checked"
                className="!mb-0"
              >
                <Checkbox style={{ whiteSpace: 'nowrap' }}>
                  Daily digest (afternoon)
                </Checkbox>
              </Form.Item>
            </Form>
          </Spin>
        </Card>
      )}
    </Space>
  );
};

export default Profile;
