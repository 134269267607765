import { FC, useState } from 'react';
import { Form, Input, message, Modal } from 'antd';
import { AiOutlineMail } from 'react-icons/ai';
import { FaRegUser } from 'react-icons/fa6';
import apiRequests from 'src/utils/api';
import apiRoutes from 'src/utils/apiRoutes';
import asyncErrorHandler from 'src/utils/asyncErrorHandler';

interface ContactModalProps {
  contact: Record<string, any>;
  onUpdated?: (contact: Record<string, any>) => void;
  onClose?: () => void;
}

const ContactModal: FC<ContactModalProps> = ({
  contact,
  onUpdated,
  onClose,
}) => {
  const [open, setOpen] = useState(true);
  const [loading, setLoading] = useState(false);
  const [form] = Form.useForm();

  const showUpdatedModal = (email: string) => {
    message.success('Team member updated successfully!');
  };

  const submit = async () => {
    setLoading(true);

    try {
      const data = await form.validateFields();

      try {
        const response = await apiRequests.put(
          `${apiRoutes.CONTACTS}/${contact.uuid}`,
          data
        );

        onUpdated?.(response.data.data);

        setOpen(false);

        showUpdatedModal(data.email);
      } catch (error) {
        asyncErrorHandler(error);
      }
    } catch (error) {
    } finally {
      setLoading(false);
    }
  };
  return (
    <Modal
      confirmLoading={loading}
      title="Edit Team Member"
      open={open}
      onCancel={() => {
        if (!loading) {
          setOpen(false);
        }
      }}
      onOk={submit}
      okText="Save"
      afterClose={() => {
        onClose?.();
      }}
      destroyOnClose
    >
      <Form
        form={form}
        layout="vertical"
        className="default-form"
        size="large"
        initialValues={contact}
      >
        <Form.Item
          label="Name"
          name="name"
          rules={[
            {
              required: true,
              message: 'This field is required',
            },
          ]}
        >
          <Input prefix={<FaRegUser />} placeholder="Name" />
        </Form.Item>

        <Form.Item
          label="E-mail"
          name="email"
          rules={[
            {
              required: true,
              type: 'email',
              message: 'Please input a e-mail!',
            },
          ]}
        >
          <Input prefix={<AiOutlineMail />} placeholder="E-mail" />
        </Form.Item>
      </Form>
    </Modal>
  );
};

export default ContactModal;
