import { Navigate, useSearchParams } from 'react-router-dom';

const RedirectTaskPage = () => {
  const [searchParams] = useSearchParams();

  const taskId = searchParams.get('uuid');
  const commentId = searchParams.get('target_comment_id');

  let url = '/dashboard';

  url += taskId ? `?task_id=${taskId}` : '';

  if (commentId) {
    url += taskId ? '&' : '?';
  }

  url += commentId ? `target_comment_id=${commentId}` : '';

  return <Navigate to={url} replace />;
};

export default RedirectTaskPage;
