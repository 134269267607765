import { DatePicker, Empty, List, Skeleton } from 'antd';
import dayjs from 'dayjs';
import { FC, useEffect } from 'react';
import { IoFolderOpenOutline } from 'react-icons/io5';
import { useDispatch, useSelector } from 'react-redux';
import Box from 'src/components/Box';
import { RootState } from 'src/store';
import { convertMinToHrsMin } from 'src/utils/stringHelpers';
import {
  clearWorkLogsData,
  getWorkLogs,
  setWorkLogsFilters,
} from 'src/store/workLogs/reducer';

const { RangePicker } = DatePicker;

const RetainerSummary: FC = () => {
  const {
    loading: workLogLoading,
    data: workLogs,
    filters,
  } = useSelector((globalState: RootState) => globalState.workLogs);

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getWorkLogs());
  }, [dispatch]);

  return workLogLoading || workLogs ? (
    <>
      <div className="mb-2 flex flex-wrap justify-between gap-2 px-1 text-blue-600">
        <div className="font-bold">
          <IoFolderOpenOutline className="mr-2 inline-block text-2xl" />

          <span className="align-middle">Usage</span>
        </div>

        <div>
          <RangePicker
            className="!mr-4"
            value={[
              filters.date_start ? dayjs(filters.date_start) : null,
              filters.date_end ? dayjs(filters.date_end) : null,
            ]}
            format="MM/DD/YYYY"
            presets={[
              {
                label: 'Last 30 days',
                value: [dayjs().subtract(30, 'd'), dayjs()],
              },
              {
                label: 'This month',
                value: [dayjs().startOf('month'), dayjs().endOf('month')],
              },
              {
                label: 'Last month',
                value: [
                  dayjs().subtract(1, 'month').startOf('month'),
                  dayjs().subtract(1, 'month').endOf('month'),
                ],
              },
              // @ts-ignore
              { label: 'All time', value: null },
            ]}
            onChange={(dates) => {
              const newDates =
                dates?.[0] && dates?.[1]
                  ? [
                      dates[0].format('YYYY-MM-DD'),
                      dates[1].format('YYYY-MM-DD'),
                    ]
                  : null;

              dispatch(clearWorkLogsData());

              dispatch(
                setWorkLogsFilters(
                  newDates
                    ? { date_start: newDates[0], date_end: newDates[1] }
                    : null
                )
              );
            }}
          />

          <span className="whitespace-nowrap">
            Total time:{' '}
            {convertMinToHrsMin(parseInt(workLogs?.total_time ?? '0', 10))}
          </span>
        </div>
      </div>

      <Box>
        {workLogLoading && !workLogs ? (
          <Skeleton className="py-4" />
        ) : (
          <List
            itemLayout="horizontal"
            dataSource={workLogs?.data}
            renderItem={(item) => (
              <List.Item
                key={item.uuid}
                className="flex w-full justify-between"
              >
                <span className="basis-2/4">{item.description}</span>

                <span className="flex-initial">
                  {dayjs(item.date).format('MMM/YYYY')}
                </span>

                <span className="flex-none">
                  {convertMinToHrsMin(item.minutes)}
                </span>
              </List.Item>
            )}
          />
        )}
      </Box>
    </>
  ) : (
    <Box>
      <Empty description={<span className="text-gray-400">No project</span>} />
    </Box>
  );
};

export default RetainerSummary;
