/* eslint-disable react-hooks/exhaustive-deps */
import { Form, message } from 'antd';
import { useContext, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import { isAxiosError, AxiosError } from 'axios';
import ProjectContext from 'src/context/project';
import { getPendingForms } from 'src/store/pendingForms/reducer';
import apiRequests from 'src/utils/api';
import apiRoutes from 'src/utils/apiRoutes';
import asyncErrorHandler from 'src/utils/asyncErrorHandler';
import { getQueryValue } from 'src/utils/stringHelpers';
import normalizeFormAnswers from 'src/utils/normalizeFormAnswers';

const useFormDrawer = () => {
  const { search, pathname } = useLocation();
  const navigate = useNavigate();
  const [form] = Form.useForm();
  const [state, setState] = useState({
    loading: false,
    saving: false,
    data: null as null | any,
    axiosError: null as AxiosError | null,
    status: null as any,
  });

  const formId = getQueryValue(search, 'form_id');
  const projectContext: any = useContext(ProjectContext);
  const dispatch = useDispatch();

  const onClose = () => {
    navigate(`${pathname}`, { preventScrollReset: true });
  };

  const updateSingleForm = async (formData: any, status = 'pending') => {
    setState((prevState: any) => ({ ...prevState, saving: true }));

    const newRes = state.data?.questions.map((item: any) => {
      item.answer = formData[item.id];
      return item;
    });

    try {
      const res = await apiRequests.put(`${apiRoutes.ANSWERS}/${formId}`, {
        questions: newRes,
        status,
      });

      if (status === 'completed') {
        message.success('Form sent successfully!');
      } else {
        message.success('Form saved');
      }

      setState((prevState: any) => ({
        ...prevState,
        saving: false,
        status: res.data.data.status,
      }));

      if (typeof projectContext?.onUpdateTrigger === 'function') {
        projectContext?.onUpdateTrigger();
      }

      dispatch(getPendingForms());
      onClose();
    } catch (error) {
      asyncErrorHandler(error);
      setState((prevState: any) => ({ ...prevState, saving: false }));
    }
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        setState((prevState: any) => ({
          ...prevState,
          loading: true,
          axiosError: null,
        }));

        const res = await apiRequests.get(`${apiRoutes.ANSWERS}/${formId}`);

        res.data.data = {
          ...res.data.data,
          ...normalizeFormAnswers(
            res.data.data.questions ?? res.data.data.form.questions
          ),
        };

        setState((prevState: any) => ({
          ...prevState,
          loading: false,
          data: res.data.data,
          status: res.data.data.status,
        }));
      } catch (error) {
        if (isAxiosError(error)) {
          const axiosError = error as AxiosError;

          setState((old) => ({
            ...old,
            axiosError: axiosError,
            loading: false,
          }));

          if (axiosError.response?.status !== 404) {
            asyncErrorHandler(error);
          }
        } else {
          asyncErrorHandler(error);
        }
      }
    };

    if (formId) {
      fetchData();
    }
  }, [formId]);

  return {
    form,
    formId,
    state,
    onClose,
    updateSingleForm,
  };
};

export default useFormDrawer;
