import React from 'react';

interface UserCardProps {
  name: string;
  role: string;
  snippet: string;
  link: string;
  imageUrl: string;
}

const UserCard: React.FC<UserCardProps> = ({
  name,
  role,
  snippet,
  link,
  imageUrl,
}) => {
  return (
    <div className="flex gap-x-6">
      <img
        className="h-32 w-32 rounded-2xl"
        src={imageUrl}
        alt={`${name}'s profile`}
      />
      <div>
        <h3 className="text-base font-semibold leading-7 tracking-tight text-gray-900">
          {name}
        </h3>
        <p className="text-sm leading-6 text-gray-400">{role}</p>
        <p className="pb-4 text-sm leading-6 text-gray-700">{snippet}</p>
        <a
          href={link}
          target="_blank"
          rel="noreferrer"
          className="rounded-md bg-[#0044cc] px-3.5 py-2.5 text-sm text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
        >
          Book a call
        </a>
      </div>
    </div>
  );
};

export default UserCard;
