import { FC, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { Progress, Skeleton } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from 'src/store';
import { getWorkLogsTotalTime } from 'src/store/workLogs/reducer';
import { convertMinToHrsMin } from 'src/utils/stringHelpers';

interface WorkLogBalanceCardProps {
  hideSeeDetail?: boolean;
}

const WorkLogBalanceCard: FC<WorkLogBalanceCardProps> = ({ hideSeeDetail }) => {
  const project = useSelector(
    (globalState: RootState) => globalState.projectRetainer.data
  );
  const { totalTime, loadingTotalTime } = useSelector(
    (globalState: RootState) => globalState.workLogs
  );

  const dispatch = useDispatch();

  const timeUsed = totalTime ?? 0;

  const budget = project?.time_report.budget;

  useEffect(() => {
    dispatch(getWorkLogsTotalTime());
  }, [dispatch]);

  return !loadingTotalTime && totalTime === null ? (
    <></>
  ) : (
    <div className="w-full text-sm text-blue-700">
      {loadingTotalTime && totalTime === null ? (
        <Skeleton />
      ) : (
        <>
          <div className="flex justify-between">
            <span className="font-bold">Hours balance</span>

            {!hideSeeDetail && (
              <Link
                to="/retainer-summary"
                className="text-xs hover:text-blue-500 xl:hidden"
              >
                View details
              </Link>
            )}
          </div>

          <div className="flex justify-between gap-2">
            <span>Available</span>

            <span>
              {convertMinToHrsMin(budget - timeUsed)} /{' '}
              {convertMinToHrsMin(budget)}
            </span>
          </div>

          <Progress
            className="!block !leading-none"
            percent={timeUsed > budget ? 100 : (timeUsed * 100) / budget}
            status={timeUsed > budget ? 'exception' : 'normal'}
            showInfo={false}
          />

          {!hideSeeDetail && (
            <Link
              to="/retainer-summary"
              className="mb-2 hidden text-xs hover:text-blue-500 xl:block"
            >
              View details
            </Link>
          )}
        </>
      )}
    </div>
  );
};

export default WorkLogBalanceCard;
