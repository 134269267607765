import { FC } from 'react';
import { Tooltip } from 'antd';
import useCommentReactions from './useCommentReactions';

interface CommentReactionsProps {
  data?: any;
  onAddReaction?: any;
  onRemoveReaction?: any;
}

const CommentReactions: FC<CommentReactionsProps> = ({
  data,
  onAddReaction,
  onRemoveReaction,
}) => {
  const { state, toggleReact } = useCommentReactions(
    data,
    onAddReaction,
    onRemoveReaction
  );
  const { reacted } = state;

  return (
    <Tooltip title={!reacted ? 'Like' : 'Remove like'}>
      <span
        className={`relative cursor-pointer text-lg text-white ${
          !reacted ? 'like-outline' : ''
        }`}
        onClick={toggleReact}
        data-like="&#128077;"
      >
        &#128077;
      </span>
    </Tooltip>
  );
};

export default CommentReactions;
