import { cloneElement, FC, MouseEventHandler, ReactNode, useRef } from 'react';
import { message, Upload, UploadProps } from 'antd';
import {
  FilePdfOutlined,
  FileOutlined,
  FileExcelOutlined,
  FileWordOutlined,
  FileZipOutlined,
} from '@ant-design/icons';
import { RcFile, UploadChangeParam } from 'antd/es/upload';
import { TbDownload } from 'react-icons/tb';
import apiRoutes from 'src/utils/apiRoutes';
import apiRequests from 'src/utils/api';
import asyncErrorHandler from 'src/utils/asyncErrorHandler';
import { formatBytes } from 'src/utils/stringHelpers';
import { UploadFileApi } from 'src/types';
import { FiUpload } from 'react-icons/fi';

const { Dragger } = Upload;

interface UploadItemProps {
  description: ReactNode;
  children: ReactNode;
  file: UploadFileApi;
}

const UploadItem: FC<UploadItemProps> = ({ description, children, file }) => {
  return (
    <div className="text-gray-400">
      {description}
      <div className="whitespace-nowrap px-2 text-xs">
        <span>{formatBytes(file.size ?? 0)} - </span>
        {children}
      </div>
    </div>
  );
};

interface UploadDraggerProps {
  setUploads: (
    file: UploadFileApi[] | ((file: UploadFileApi[]) => UploadFileApi[])
  ) => void;
  uploads: UploadFileApi[];
  maxSize?: string;
  setLoading: (loading: boolean) => void;
  onRemove?: (file: UploadFileApi) => void;
  showRemoveIcon?: boolean;
  showDownloadIcon?: boolean;
  showDownloadAll?: boolean;
  onDownloadAll?: MouseEventHandler<HTMLButtonElement>;
  onRequest?: (response: { url: string; uuid: string }) => Promise<unknown>;
  children?: ReactNode;
}

const UploadDragger: FC<UploadDraggerProps> = ({
  uploads,
  maxSize,
  setUploads,
  setLoading,
  onRemove: onRemoveParent,
  showDownloadIcon = false,
  showRemoveIcon = false,
  showDownloadAll = false,
  onDownloadAll,
  onRequest,
  children,
}) => {
  const filesDownloadingRef = useRef<string[]>([]);

  const customRequest: UploadProps['customRequest'] = async (options) => {
    const { onSuccess, onError, file } = options;

    const abort = new AbortController();

    const current = uploads.find((item) => item.uid === (file as RcFile).uid);

    if (current) current.abortController = abort;

    try {
      const response = await apiRequests.uploadFile({
        file: file as File,
        type: 'attachment',
        abortController: abort,
      });

      if (onRequest) {
        const sentToApi: any = await onRequest(response);

        if (sentToApi) {
          response.url = response.url.replace('/tmp/', '/uploads/');
          response.stated = true;
        }
      }

      if (onSuccess) onSuccess(response);
    } catch (err) {
      if (onError)
        onError({
          status: 500,
          method: 'PUT',
          url: 'tes',
          name: 'eeee',
          message: 'tess',
        });
    }
  };

  const onChange = ({ file, fileList }: UploadChangeParam<UploadFileApi>) => {
    const hasLoading = fileList.find((item) => item.status === 'uploading');

    setLoading(!!hasLoading);

    if (file.status === 'error') {
      message.error(`${file.name} file upload failed.`);
    }

    if (file.status === 'done' && file.response?.url) {
      file.url = file.response.url;
    }

    setUploads(fileList);
  };

  const onRemove: UploadProps['onRemove'] = async (upload) => {
    if (onRemoveParent) onRemoveParent(upload);

    setUploads((prev) => prev.filter((item) => item.uid !== upload.uid));

    if (upload.response?.stated) {
      try {
        await apiRequests.delete(
          `${apiRoutes.UPLOADS}/${upload.response.uuid}`
        );
      } catch (error) {
        asyncErrorHandler(error);
      }
    }
  };

  const onDownload: UploadProps['onDownload'] = (upload: UploadFileApi) => {
    const uuid = upload.response?.uuid;

    if (!uuid || filesDownloadingRef.current.includes(upload.uid)) {
      return;
    }

    filesDownloadingRef.current.push(uuid);

    apiRequests
      .get(`${apiRoutes.UPLOADS}/${uuid}/download`)
      .then((response) => {
        const link = document.createElement('a');
        link.href = response.data;
        link.download = upload.name;
        link.click();
      })
      .catch((error) => {
        asyncErrorHandler(error);
      })
      .finally(() => {
        const index = filesDownloadingRef.current.indexOf(uuid);

        if (index > -1) {
          filesDownloadingRef.current.splice(index, 1);
        }
      });
  };

  return (
    <>
      <Dragger
        className={`flex w-full flex-col items-center ${
          !uploads.length ? 'no-files' : ''
        } ${children ? 'clean-drag-area' : ''}`}
        customRequest={customRequest}
        onDownload={onDownload}
        onChange={onChange}
        onRemove={onRemove}
        onPreview={(file) => {
          if (file.status === 'done') {
            window.open(
              !file.response?.stated
                ? file.url
                : `/file-viewer/${file.response.uuid}`,
              '_blank'
            );
          }
        }}
        listType="picture"
        fileList={uploads}
        showUploadList={{
          showPreviewIcon: true,
          showDownloadIcon,
          showRemoveIcon,
          downloadIcon: 'Download',
        }}
        multiple
        itemRender={(element, file) => {
          const [thumbnail, item, ...rest] = element.props.children;
          const [description, ...restItem] = item;

          return cloneElement(element, {
            children: [
              thumbnail,
              <UploadItem
                description={description}
                children={restItem}
                file={file}
              />,
              ...rest,
            ],
          });
        }}
        iconRender={(upload: UploadFileApi) => {
          if (upload.extension === 'pdf' || upload.type === 'application/pdf') {
            return <FilePdfOutlined className="!text-red-700" />;
          }

          if (
            ['xlsx', 'xlsm', 'xls', 'xlt'].includes(upload.extension || '') ||
            [
              'application/vnd.ms-excel',
              'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
            ].includes(upload.type || '')
          ) {
            return <FileExcelOutlined className="!text-green-700" />;
          }

          if (
            ['doc', 'docx', 'dotx', 'dot'].includes(upload.extension || '') ||
            [
              'application/msword',
              'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
            ].includes(upload.type || '')
          ) {
            return <FileWordOutlined className="!text-blue-700" />;
          }

          if (
            ['zip', '7z', 'rar'].includes(upload.extension || '') ||
            [
              'application/zip',
              'application/x-7z-compressed',
              'application/vnd.rar',
            ].includes(upload.type || '')
          ) {
            return <FileZipOutlined />;
          }

          return <FileOutlined />;
        }}
      >
        {children || (
          <div>
            <FiUpload className="inline text-xl text-blue-700" />

            <p className="mt-1 font-bold text-blue-700">
              Upload files {maxSize ? `(${maxSize} max file size)` : ''}
            </p>

            <p className="text-xs text-[#00000073]">
              Click or drag file to this area to upload
            </p>
          </div>
        )}
      </Dragger>

      {showDownloadAll && uploads.length > 0 && (
        <button
          onClick={onDownloadAll}
          className="mt-2 w-full rounded-md border border-[#0044cc33] bg-[#ECF5FE] px-2 py-1 text-center"
        >
          <TbDownload className="mr-2 inline text-2xl" />
          <span className="align-middle font-semibold text-[#0044CC]">
            Download all files
          </span>
        </button>
      )}
    </>
  );
};

export default UploadDragger;
