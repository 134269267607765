import { createSlice } from '@reduxjs/toolkit';
import { ProjectResponse } from 'src/types';

const initialState = {
  loading: false,
  data: undefined as ProjectResponse | null | undefined,
  error: null,
};

export const projectAdvertisingSlice = createSlice({
  name: 'projectAdvertising',
  initialState,
  reducers: {
    getProjectAdvertising: () => {},
    setProjectAdvertisingLoading: (state) => ({ ...state, loading: true }),
    setProjectAdvertisingData: (state, action) => ({
      ...state,
      loading: false,
      data: action.payload,
    }),
    clearProjectAdvertisingData: () => initialState,
    setProjectAdvertisingError: (state, action) => ({
      ...state,
      loading: false,
      error: action.payload,
    }),
  },
});

export const {
  setProjectAdvertisingData,
  setProjectAdvertisingError,
  setProjectAdvertisingLoading,
  getProjectAdvertising,
  clearProjectAdvertisingData,
} = projectAdvertisingSlice.actions;

export default projectAdvertisingSlice.reducer;
