import { createSlice } from '@reduxjs/toolkit';
import { ApiResponse } from 'src/types';

export interface ProjectsStateType {
  loading: boolean;
  data: ApiResponse<any> | null;
  error: null | string;
}

const initialState = {
  loading: false,
  data: null,
  error: null,
};

export const dashboardProjectsSlice = createSlice({
  name: 'dashboardProjects',
  initialState,
  reducers: {
    getDashboardProjects: (state, action: any) => {},
    setDashboardProjectsLoading: (state) => ({ ...state, loading: true }),
    setDashboardProjectsData: (state, action) => ({
      ...state,
      loading: false,
      data: action.payload,
    }),
    clearDashboardProjectsData: () => initialState,
    setDashboardProjectsError: (state, action) => ({
      ...state,
      loading: false,
      error: action.payload,
    }),
  },
});

export const {
  setDashboardProjectsData,
  setDashboardProjectsError,
  setDashboardProjectsLoading,
  getDashboardProjects,
  clearDashboardProjectsData,
} = dashboardProjectsSlice.actions;

export default dashboardProjectsSlice.reducer;
