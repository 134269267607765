import { FC } from 'react';
import Header from './Header';
import Paragraph from './Paragraph';
import Short from './Short';
import Checkbox from './Checkbox';
import DateInput from './DateInput';
import Dropdown from './Dropdown';
import Number from './Number';

interface FormQuestionsProps {
  questions: any[];
  disabled?: boolean;
}

const inputComponents: Record<string, any> = {
  Checkbox: Checkbox,
  Date: DateInput,
  Dropdown: Dropdown,
  Header: Header,
  Paragraph: Paragraph,
  Short: Short,
  Number: Number,
};

const FormQuestions: FC<FormQuestionsProps> = ({ questions, disabled }) => {
  return (
    <>
      {questions.map((question) => {
        const Comp = inputComponents[question.type];

        return Comp ? (
          <Comp question={question} questions={questions} disabled={disabled} />
        ) : undefined;
      })}
    </>
  );
};

export default FormQuestions;
