import { FC, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import AxiosErrorResult from 'src/components/AxiosErrorResult';
import LoadingPage from 'src/components/LoadingPage';
import asyncErrorHandler from 'src/utils/asyncErrorHandler';
import apiRequests from 'src/utils/api';
import apiRoutes from 'src/utils/apiRoutes';
import FileViewerHeader from './FileViewerHeader';
import FileViewerBody from './FileViewerBody';

const FileViewerShow: FC = () => {
  const [file, setFile] = useState<Record<string, any>>();
  const [downloading, setDownloading] = useState(false);
  const [fetchError, setFetchError] = useState<any>();

  const { uuid } = useParams<{ uuid: string }>();

  const onDownloadHandler = async () => {
    if (!file) return;

    setDownloading(true);

    try {
      const response = await apiRequests.get(
        `${apiRoutes.UPLOADS}/${uuid}/download`
      );

      const link = document.createElement('a');
      link.href = response.data;
      link.download = file.name;
      link.click();
    } catch (error) {
      asyncErrorHandler(error);
    } finally {
      setDownloading(false);
    }
  };

  useEffect(() => {
    const fetch = async () => {
      try {
        const response = await apiRequests.get(`${apiRoutes.UPLOADS}/${uuid}`);

        setFile(response.data.data);
      } catch (error) {
        setFetchError(error);
      }
    };

    fetch();
  }, [uuid]);

  if (fetchError) {
    return <AxiosErrorResult error={fetchError} />;
  }

  if (!file) {
    return <LoadingPage />;
  }

  return (
    <div className="relative flex min-h-[100vh] flex-col">
      <FileViewerHeader
        file={file}
        downloading={downloading}
        onDownload={onDownloadHandler}
      />

      <FileViewerBody
        file={file}
        downloading={downloading}
        onDownload={onDownloadHandler}
      />
    </div>
  );
};

export default FileViewerShow;
