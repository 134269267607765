import { Spin } from 'antd';

const Index = () => {
  return (
    <div className="flex h-screen w-full items-center justify-center">
      <Spin tip="Loading...">
        <div className="h-2 w-20" />
      </Spin>
    </div>
  );
};

export default Index;
