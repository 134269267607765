import { AxiosResponse } from 'axios';
import {
  call,
  put,
  select,
  StrictEffect,
  takeLatest,
} from 'redux-saga/effects';
import { ApiResponse, InboxResponse, UserResponse } from 'src/types';
import apiRequests from 'src/utils/api';
import apiRoutes from 'src/utils/apiRoutes';
import asyncErrorHandler from 'src/utils/asyncErrorHandler';
import {
  getInbox,
  setInboxCount,
  setInboxData,
  setInboxError,
  setInboxLoading,
} from './reducer';

const { get } = apiRequests;

function* getInboxSagaListener(action: any): any {
  try {
    yield put(setInboxLoading());

    const res: AxiosResponse<ApiResponse<InboxResponse>> = yield call(
      get,
      apiRoutes.INBOX,
      {
        'page[size]': 500,
        ...action.payload,
      }
    );

    const user: UserResponse | null = yield select((state) => state.auth.user);

    const count = res.data.data.filter((item) => {
      const relatedUser = item.related_users.find((x) => x.uuid === user?.uuid);
      return relatedUser && !relatedUser?.read_at;
    }).length;

    yield put(setInboxData(res.data));
    yield put(setInboxCount(count));
  } catch (error) {
    yield put(setInboxError(error));
    asyncErrorHandler(error);
  }
}

function* InboxSaga(): Generator<StrictEffect> {
  yield takeLatest(getInbox.type, getInboxSagaListener);
}

export default InboxSaga;
