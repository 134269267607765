import { Link } from 'react-router-dom';
import { PiWarningOctagon } from 'react-icons/pi';

export default function Toast() {
  return (
    <div className="sticky bottom-2 left-0 z-10 mx-auto">
      <div className="mx-6 mt-4 flex flex-wrap items-center gap-2 gap-y-3 rounded-md border border-red-500 bg-red-50 px-5 py-3 text-red-500">
        <PiWarningOctagon className="h-6 w-6 flex-none" aria-hidden="true" />

        <span className="pr-3 text-lg font-bold">Action required</span>

        <div className="flex-1 text-center">
          <Link
            to="/pending"
            className="inline-block whitespace-nowrap rounded-md bg-red-500 px-4 py-2 leading-none text-white transition-all hover:bg-red-700"
          >
            Fix it now
          </Link>
        </div>
      </div>
    </div>
  );
}
