import {
  createContext,
  ReactNode,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { AxiosResponse } from 'axios';
import apiRequests from 'src/utils/api';
import asyncErrorHandler from 'src/utils/asyncErrorHandler';

export interface InfinityScrollContext<T extends Record<string, unknown>> {
  items: T[];
  hasMore: boolean;
  initialFetch: boolean;
  addItem: (newItem: T) => void;
  removeItem: (targetItem: T) => void;
  removeAllItems: () => void;
  updateItem: (targetItem: T, newValues: Partial<T>) => void;
  updateAllItems: (newValues: Partial<T>) => void;
  fetchMoreItems: () => Promise<void>;
  resetFetch: () => void;
}

interface InfinityScrollProviderProps {
  url: string;
  sortKey: string;
  idKey?: string;
  params?: Record<string, unknown>;
  children: ReactNode;
}

const createInfinityScrollApiContext = <
  T extends Record<string, unknown>
>() => {
  const InfinityScrollContext = createContext<InfinityScrollContext<T>>({
    items: [],
    hasMore: true,
    initialFetch: false,
    addItem: () => {},
    removeItem: () => {},
    removeAllItems: () => {},
    updateItem: () => {},
    updateAllItems: () => {},
    fetchMoreItems: async () => {},
    resetFetch: () => {},
  });

  const InfinityScrollProvider = ({
    url,
    sortKey,
    idKey = 'id',
    params,
    children,
  }: InfinityScrollProviderProps) => {
    const [items, setItems] = useState<T[]>([]);
    const [hasMore, setHasMore] = useState(true);
    const [initialFetch, setInitialFetch] = useState(false);
    const promiseRef = useRef<Promise<AxiosResponse> | null>(null);

    const value = useMemo<InfinityScrollContext<T>>(() => {
      return {
        items,
        hasMore,
        initialFetch,
        addItem: (newItem: T) => {
          setItems((oldItems) => {
            if (oldItems.find((i) => i[idKey] === newItem[idKey])) {
              return oldItems;
            }

            return [newItem, ...oldItems];
          });
        },
        updateItem: (targetItem: T, newValues: Partial<T>) => {
          setItems((oldItems) =>
            oldItems.map((item) =>
              item.id === targetItem.id ? { ...targetItem, ...newValues } : item
            )
          );
        },
        updateAllItems: (newValues: Partial<T>) => {
          setItems((oldItems) =>
            oldItems.map((item) => ({ ...item, ...newValues }))
          );
        },
        removeItem: (targetItem: T) => {
          setItems((oldItems) =>
            oldItems.filter((i) => i[idKey] !== targetItem[idKey])
          );
        },
        removeAllItems: () => {
          setItems([]);
        },
        fetchMoreItems: async () => {
          if (!initialFetch) {
            setInitialFetch(true);
          }

          try {
            const promise = apiRequests.get(url, {
              'page[size]': 15,
              after: items[items.length - 1]?.[sortKey] ?? 0,
              ...(params ?? {}),
            });

            promiseRef.current = promise;

            const response = await promise;

            if (promise !== promiseRef.current) {
              return;
            }

            const { data } = response.data;

            setItems((oldItems) => {
              const newData = data.data.filter(
                (x: Record<string, unknown>) =>
                  !oldItems.find((i) => i[idKey] === x[idKey])
              );

              return [...oldItems, ...newData];
            });

            setHasMore(data.current_page < data.last_page);
          } catch (error) {
            asyncErrorHandler(error);
          }
        },
        resetFetch: () => {
          setHasMore(true);
          setItems([]);
          setInitialFetch(false);
        },
      };
    }, [hasMore, items, params, sortKey, idKey, url, initialFetch]);

    useEffect(() => {
      setHasMore(true);
      setItems([]);
      setInitialFetch(false);
    }, [url, params, sortKey]);

    return (
      <InfinityScrollContext.Provider value={value}>
        {children}
      </InfinityScrollContext.Provider>
    );
  };

  return { Context: InfinityScrollContext, Provider: InfinityScrollProvider };
};

export default createInfinityScrollApiContext;
