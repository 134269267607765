import dayjs from 'dayjs';

export default function normalizeFormAnswers(
  questions?: Record<string, any>[] | null
) {
  if (!questions) {
    return { questions: [], answers: [] };
  }

  return {
    questions,
    answers: questions.reduce((acc: any, item: any) => {
      acc[item.id] =
        item.formType === 'Date' && item.answer
          ? dayjs(item.answer)
          : item.answer;
      return acc;
    }, {}),
  };
}
