import { FC } from 'react';
import dayjs from 'dayjs';
import { useSelector } from 'react-redux';
import RichTextEditor from 'src/components//RichTextEditor';
import { mentionCommentParser } from 'src/utils/stringHelpers';
import { CommentResponse, UserResponse } from 'src/types';

interface TaskLastCommentProps {
  comment: CommentResponse;
}

const TaskLastComment: FC<TaskLastCommentProps> = ({ comment }) => {
  const user: UserResponse = useSelector(
    (globalState: any) => globalState.auth.user
  );

  const relatedUser = comment.related_users.find((x) => x.uuid === user.uuid);

  return (
    <div className="relative rounded-md border border-[#DADADA] bg-[#F9F9F9] px-2 py-1 leading-none hover:bg-[#F9F9F9B3]">
      {relatedUser && !relatedUser.read_at ? (
        <div className="absolute -right-0.5 -top-0.5 z-10 h-2.5 w-2.5 rounded-full bg-red-500" />
      ) : undefined}

      <div className="mb-0.5 flex items-center justify-between text-xs">
        <div className="font-bold">{comment.creator.name}</div>

        <div>{dayjs(comment.created_at).fromNow(true)}</div>
      </div>

      <RichTextEditor
        className={`comment-styles resume resume-one-line ${
          comment.deleted_at ? 'italic text-gray-400' : ''
        }`}
        defaultValue={mentionCommentParser(comment.text)}
        readonly
        noContainer
      />
    </div>
  );
};

export default TaskLastComment;
