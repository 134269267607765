import { FC } from 'react';
import { Button, Typography } from 'antd';
import imageSvg from 'src/assets/images/undraw_heavy_box_agqi.svg';

interface DownloadFileCardProps {
  downloading: boolean;
  onDownload: () => void;
}

const DownloadFileCard: FC<DownloadFileCardProps> = ({
  downloading,
  onDownload,
}) => {
  return (
    <div
      style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}
    >
      <img
        src={imageSvg}
        alt="file too heavy"
        style={{ maxWidth: '300px' }}
        className="mb-l"
      />

      <Typography.Title level={3}>Can’t load this file</Typography.Title>

      <Typography.Text className="mb-m">
        The preview doesn&apos;t support this type of file.
      </Typography.Text>

      <Button type="default" loading={downloading} onClick={onDownload}>
        Download
      </Button>
    </div>
  );
};

export default DownloadFileCard;
