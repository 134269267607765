import { PiWarningOctagon } from 'react-icons/pi';

const AlertPendingCard = () => {
  return (
    <div className="space-y-2 rounded-md border border-red-500 bg-red-50 px-5 py-3">
      <div className="flex items-center text-red-500">
        <PiWarningOctagon className="mr-3 h-8 w-8" />

        <p className="text-2xl font-bold">Action required</p>
      </div>

      <div className="text-xs text-gray-500">
        <p>Complete the task(s) below to avoid project delays:</p>

        <p>1-3 days late = 1 week delay</p>

        <p>4-7 days late = 2 week delay</p>
      </div>
    </div>
  );
};

export default AlertPendingCard;
