const NavbarBrand = () => {
  return (
    <svg
      width="42"
      height="42"
      viewBox="0 0 133 133"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle cx="66.5" cy="66.5" r="66.5" fill="white" />
      <path
        d="M57.4712 99.3377C52.6805 99.3377 52.2198 98.3219 52.2198 92.4116V31H51.0221C50.7458 31 50.5615 31.0923 50.3772 31.1847C48.9032 31.9235 47.4291 32.6623 45.6787 33.2164C44.1125 33.8628 42.2699 34.3245 40.2431 34.6939C39.8745 34.7863 39.9667 35.4327 40.2431 35.4327C44.9416 34.6016 45.6787 34.7863 45.6787 41.7124V92.504C45.6787 92.9657 45.6787 93.7045 45.6787 94.3509C45.5865 98.5066 44.7574 99.3377 40.5194 99.3377C40.3352 99.3377 40.3352 99.8918 40.5194 99.8918C41.8093 99.8918 43.8361 99.8918 45.6787 99.8918H52.2198C53.9703 99.8918 55.9971 99.8918 57.4712 99.8918C57.6554 99.8918 57.6554 99.3377 57.4712 99.3377Z"
        fill="#0044CC"
      />
      <path
        d="M83.6926 77.0818L72.6372 71.0792C63.0558 65.723 62.8715 56.3034 74.8483 55.5646C86.825 54.9182 88.6676 64.7071 89.4967 70.9868C89.4967 70.9868 89.5889 71.0792 89.8653 71.0792C90.1416 71.0792 90.0495 70.9868 90.0495 70.9868V59.628C90.0495 59.628 87.2857 54.9182 74.664 54.9182C63.5164 54.9182 60.6604 60.7362 60.6604 60.7362C57.0674 67.1082 59.2785 71.9103 69.689 78.19L80.7445 84.1003C89.0361 88.9024 91.9842 99.7995 78.5334 100.261C61.4896 101 60.9368 89.3641 60.1998 82.161C60.1998 82.161 60.1076 82.0686 59.9234 82.0686C59.5549 82.0686 59.5549 82.2533 59.5549 82.2533V95.3668C59.5549 95.3668 65.1747 101 78.4413 101C88.0227 101 91.5236 96.4749 91.5236 96.4749C96.6828 90.0106 93.6425 82.438 83.6926 77.0818Z"
        fill="#0044CC"
      />
    </svg>
  );
};

export default NavbarBrand;
