import auth from './auth/saga';
import tasks from './tasks/saga';
import projectRetainer from './projectRetainer/saga';
import dashboardProjects from './dashboardProjects/saga';
import dashboardTasks from './dashboardTasks/saga';
import pendingTasks from './pendingTasks/saga';
import pendingForms from './pendingForms/saga';
import inbox from './inbox/saga';
import activities from './activities/saga';
import projectAdvertising from './projectAdvertising/saga';
import workLogs from './workLogs/saga';
import contacts from './contacts/saga';
import pendingInvites from './pendingInvites/saga';

import { all, fork } from 'redux-saga/effects';

export default function* root() {
  yield all([
    fork(auth),
    fork(tasks),
    fork(projectRetainer),
    fork(inbox),
    fork(dashboardProjects),
    fork(dashboardTasks),
    fork(pendingTasks),
    fork(activities),
    fork(pendingForms),
    fork(projectAdvertising),
    fork(workLogs),
    fork(contacts),
    fork(pendingInvites),
  ]);
}
