import { FC, useRef, useState } from 'react';
import ReactPlayer from 'react-player';
import { Button, Collapse, Modal } from 'antd';
import useBreakpoint from 'antd/es/grid/hooks/useBreakpoint';

const MenuRightVideo: FC = () => {
  const [videoOpen, setVideoOpen] = useState(false);
  const [activeKey, setActiveKey] = useState<string>();
  const breakpointMountedRef = useRef(false);

  const breakpoints = useBreakpoint();

  if (!breakpointMountedRef.current && breakpoints.xl !== undefined) {
    breakpointMountedRef.current = true;
    setActiveKey(breakpoints.xl ? '1' : undefined);
  }

  return (
    <Collapse
      activeKey={activeKey}
      onChange={setActiveKey as any}
      size="small"
      className="w-full !bg-white xl:!mt-4 xl:w-[210px]"
      bordered={false}
      expandIconPosition="end"
    >
      <Collapse.Panel
        header={
          <div className="font-bold text-blue-600 md:text-2xl xl:text-base">
            Explore Lionshare’s latest updates!
          </div>
        }
        key="1"
      >
        <div className="flex flex-col justify-between gap-4 md:flex-row xl:flex-col">
          <Modal
            open={videoOpen}
            footer={false}
            closable={false}
            width={800}
            wrapClassName="video-modal"
            onCancel={() => setVideoOpen(false)}
            destroyOnClose
          >
            <ReactPlayer
              url="https://player.vimeo.com/video/1006180341"
              width="100%"
              height="auto"
              style={{ aspectRatio: 1.8, background: '#FFF' }}
              controls
            />
          </Modal>

          {/*<h5 className="flex-1 font-bold text-blue-600 md:text-4xl xl:text-base">*/}
          {/*  */}
          {/*</h5>*/}

          <ul className="flex-none list-disc pl-4 text-sm md:w-3/12 xl:w-auto">
            <li>A sleek, simplified, and stunning new look</li>
            <li>Faster task creation</li>
            <li>New messages highlighted with red dots</li>
            <li>Task-specific messaging</li>
            <li>An intuitive menu with everything you need</li>
          </ul>

          <div className="flex-none space-y-4 md:w-3/12 xl:w-auto">
            <p className="text-sm">
              Don’t miss out—watch our quick 1-minute video to see all the
              updates!
            </p>

            <Button
              className="w-full"
              type="primary"
              onClick={() => setVideoOpen(true)}
            >
              Watch now
            </Button>
          </div>
        </div>
      </Collapse.Panel>
    </Collapse>
  );
};

export default MenuRightVideo;
