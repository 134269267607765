import { AxiosResponse } from 'axios';
import { call, put, StrictEffect, takeLatest } from 'redux-saga/effects';

import {
  getDashboardProjects,
  setDashboardProjectsData,
  setDashboardProjectsError,
  setDashboardProjectsLoading,
} from './reducer';
import apiRequests from 'src/utils/api';
import apiRoutes from 'src/utils/apiRoutes';

const { get } = apiRequests;

function* getDashboardProjectsSagaListener(action: any) {
  try {
    yield put(setDashboardProjectsLoading());
    const res: AxiosResponse = yield call(
      get,
      apiRoutes.PROJECTS,
      action.payload
    );
    yield put(setDashboardProjectsData(res.data));
  } catch (error) {
    yield put(setDashboardProjectsError(error));
  }
}
function* dashboardProjectsSaga(): Generator<StrictEffect> {
  yield takeLatest(getDashboardProjects.type, getDashboardProjectsSagaListener);
}

export default dashboardProjectsSaga;
