import { AxiosResponse } from 'axios';
import {
  call,
  put,
  select,
  StrictEffect,
  takeLatest,
} from 'redux-saga/effects';
import apiRequests from 'src/utils/api';
import apiRoutes from 'src/utils/apiRoutes';

import {
  getActivities,
  setActivitiesData,
  setActivitiesError,
  setActivitiesLoading,
} from './reducer';
import asyncErrorHandler from 'src/utils/asyncErrorHandler';

const { get } = apiRequests;

function* getActivitiesSagaListener(action: any): any {
  const user = yield select((state) => state.auth.user);

  try {
    yield put(setActivitiesLoading());
    const res: AxiosResponse = yield call(
      get,
      `${apiRoutes.PROJECT_LOG}&filters[customer.uuid][]=${user?.contact?.customer?.uuid}`,
      action.payload
    );
    yield put(setActivitiesData(res.data));
  } catch (error) {
    yield put(setActivitiesError(error));
    asyncErrorHandler(error);
  }
}

function* ActivitiesSaga(): Generator<StrictEffect> {
  yield takeLatest(getActivities.type, getActivitiesSagaListener);
}

export default ActivitiesSaga;
