import React, { useEffect, useState } from 'react';
import { notification, Skeleton } from 'antd';
import { MdError } from 'react-icons/md';
import LionshareSVG from 'src/icons/LionshareSvg';
import apiRoutes from 'src/utils/apiRoutes';
import apiRequests from 'src/utils/api';
import { Navigate, useNavigate, useSearchParams } from 'react-router-dom';
import RegisterForm from './RegisterForm';

const Register: React.FC = () => {
  const [inviteData, setInviteData] = useState<any>();
  const [fetchLoading, setFetchLoading] = useState(true);

  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const inviteId = searchParams.get('invite_id');

  useEffect(() => {
    let cancelPromise = false;

    const fetchCustomer = async () => {
      try {
        setFetchLoading(true);

        const res = await apiRequests.get(
          `${apiRoutes.CUSTOMER_INVITE}/${inviteId}`
        );

        if (cancelPromise) return;

        setInviteData(res.data.data);
      } catch (error: any) {
        if (cancelPromise) return;

        notification.open({
          message: 'Invalid Invite',
          description:
            'This invite is invalid, please ask the responsible to send you another invite.',
          icon: <MdError className="text-red-600" />,
          duration: 0,
        });

        navigate('/login');
      } finally {
        if (!cancelPromise) {
          setFetchLoading(false);
        }
      }
    };

    if (inviteId) {
      fetchCustomer();

      return () => {
        cancelPromise = true;
      };
    }
  }, [inviteId, navigate]);

  if (!inviteId) {
    return <Navigate to="/login" replace={true} />;
  }

  return (
    <div className="flex h-screen w-full flex-col md:flex-row">
      <div className="flex w-full flex-col items-center justify-center bg-[#0044cc] p-4 sm:h-full sm:p-2">
        <div className="max-w-[140px] md:max-w-[440px]">
          <LionshareSVG />
        </div>
        <div className="hidden max-w-[400px] pt-6 sm:block">
          <p className=" px-4 font-light text-white  md:text-[18px]">
            Our all-in-one solution that connects you with our team from any
            device, providing a request system, real-time notifications,
            resources, and more.
          </p>
        </div>
      </div>
      <div className="flex w-full flex-col items-center justify-center px-8">
        <div className="w-full max-w-[320px]">
          {fetchLoading ? (
            <Skeleton />
          ) : (
            <RegisterForm
              email={inviteData.email}
              customerUuid={inviteData.customer.uuid}
            />
          )}
        </div>
      </div>
    </div>
  );
};

export default Register;
