import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  loading: false,
  data: null as any | null,
  error: null,
};

export const activitiesSlice = createSlice({
  name: 'activities',
  initialState,
  reducers: {
    getActivities: () => {},
    setActivitiesLoading: (state) => ({ ...state, loading: true }),
    setActivitiesData: (state, action) => ({
      ...state,
      loading: false,
      data: action.payload,
    }),
    clearActivitiesData: () => initialState,
    setActivitiesError: (state, action) => ({
      ...state,
      loading: false,
      error: action.payload,
    }),
  },
});

export const {
  setActivitiesData,
  setActivitiesError,
  setActivitiesLoading,
  getActivities,
  clearActivitiesData,
} = activitiesSlice.actions;

export default activitiesSlice.reducer;
