export function sortTasksByStatus<T extends Object[]>(data: T) {
  return data
    .sort((a: any, b: any) => {
      if (a.task_status === 'pending') {
        return b.task_status === 'pending' ? 0 : -1;
      }

      return b.task_status === 'pending' ? 1 : 0;
    })
    .sort((a: any, b: any) => {
      if (a.task_status === 'waiting_approval') {
        return b.task_status === 'waiting_approval' ? 0 : -1;
      }

      return b.task_status === 'waiting_approval' ? 1 : 0;
    });
}

export function sortTaskByDueDate<T extends Object[]>(data: T) {
  return data.sort((a: any, b: any) => {
    if (!a.task_end_date) {
      return b.task_end_date ? 1 : 0;
    }

    if (!b.task_end_date) {
      return -1;
    }

    return (
      new Date(a.task_end_date).getTime() - new Date(b.task_end_date).getTime()
    );
  });
}
