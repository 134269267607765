import { FC } from 'react';
import { Divider } from 'antd';

interface HeaderProps {
  question: any;
}

const Header: FC<HeaderProps> = ({ question }) => {
  return (
    <div>
      <div className="mt-12 text-lg">{question.label}</div>

      <Divider />
    </div>
  );
};

export default Header;
