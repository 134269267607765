import { FC } from 'react';

interface DropdownHeaderProps {
  onMarkAllRead: () => void;
}

const DropdownHeader: FC<DropdownHeaderProps> = ({ onMarkAllRead }) => {
  const markAllReadHandler = () => {
    onMarkAllRead();
  };

  return (
    <div className="bl-dropdown-header">
      <span>NOTIFICATIONS</span>

      <button
        type="button"
        className="default-button"
        onClick={markAllReadHandler}
      >
        Mark All Read
      </button>
    </div>
  );
};

export default DropdownHeader;
