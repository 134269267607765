import { FC, RefObject } from 'react';
import { Popconfirm, Space } from 'antd';
import type { RichTextEditorHandle } from 'src/components/RichTextEditor';
import { getMentionUuidFromNode } from 'src/utils/stringHelpers';
import { UserResponse } from 'src/types';
import ReactionCounter from '../ReactionCounter';
import CommentReactions from '../CommentReactions';

interface CommentActionsBaseProps {
  comment: any;
  user: UserResponse;
  editing: boolean;
  editorText: RefObject<RichTextEditorHandle>;
  onReplyTo: (replyToUuid: string, replyTo: any, creator?: any) => void;
  onEdit: (edit: boolean) => void;
}

export interface CommentActionsParentProps extends CommentActionsBaseProps {
  parentEl?: undefined;
  onAddReaction: (uuid: string, reaction: any) => void;
  onRemoveReaction: (uuid: string, reaction: any) => void;
  onUpdate: (
    uuid: string,
    text: string,
    mentions: string[],
    onFinish: () => void
  ) => Promise<void>;
  onDelete: (uuid: string) => Promise<void>;
}

export interface CommentActionsChildrenProps extends CommentActionsBaseProps {
  parentEl: Record<string, any>;
  onAddReaction: (parentUuid: string, uuid: any, reaction: any) => void;
  onRemoveReaction: (parentUuid: string, uuid: any, reaction: any) => void;
  onUpdate: (
    uuid: string,
    parentUuid: string,
    text: string,
    mentions: string[],
    onFinish: () => void
  ) => Promise<void>;
  onDelete: (uuid: string, parentUuid: string) => Promise<void>;
}

export type CommentActionsProps =
  | CommentActionsParentProps
  | CommentActionsChildrenProps;

const deletedMessage = '<i class="disabled">[Comment Deleted]</i>';

const CommentActions: FC<CommentActionsProps> = (props) => {
  const { comment, user, editing, editorText, onReplyTo, onEdit } = props;

  return (
    <>
      <Space size={12} style={{ marginTop: 12, lineHeight: 1 }}>
        <CommentReactions
          onAddReaction={(reaction: any) => {
            if (props.parentEl) {
              props.onAddReaction(props.parentEl.uuid, comment.uuid, reaction);
            } else {
              props.onAddReaction(comment.uuid, reaction);
            }
          }}
          onRemoveReaction={(reaction: any) => {
            if (props.parentEl) {
              props.onRemoveReaction(
                props.parentEl.uuid,
                comment.uuid,
                reaction
              );
            } else {
              props.onRemoveReaction(comment.uuid, reaction);
            }
          }}
          data={comment}
        />

        <button
          type="button"
          style={{ padding: 0 }}
          onClick={() => {
            let targetReply =
              comment.creator.uuid !== user.uuid ? comment.creator : null;

            if (
              !targetReply &&
              props.parentEl &&
              props.parentEl.creator.uuid !== user.uuid
            ) {
              targetReply = props.parentEl.creator;
            }

            onReplyTo((props.parentEl ?? comment).uuid, comment, targetReply);
          }}
        >
          Reply
        </button>

        {editing && (
          <button
            type="button"
            className="text-blue-600"
            style={{ padding: 0 }}
            onClick={() => {
              const newComment = editorText.current
                ?.getQuill()
                ?.getEditorContents() as string;

              if (!newComment) return;

              if (props.parentEl) {
                props.onUpdate(
                  comment.uuid,
                  props.parentEl.uuid,
                  newComment,
                  getMentionUuidFromNode(newComment),
                  () => onEdit(false)
                );
              } else {
                props.onUpdate(
                  comment.uuid,
                  newComment,
                  getMentionUuidFromNode(newComment),
                  () => onEdit(false)
                );
              }
            }}
          >
            Save
          </button>
        )}

        {editing && (
          <button
            type="button"
            className="text-red-500"
            style={{ padding: 0 }}
            onClick={() => {
              onEdit(false);

              const quill = editorText.current?.getQuill();

              if (editorText.current && quill?.editor) {
                quill.setEditorContents(quill.editor, comment.text);
              }
            }}
          >
            Cancel
          </button>
        )}

        {user?.uuid === comment.creator.uuid &&
        !editing &&
        !comment.deleted_at ? (
          <>
            <button
              type="button"
              className="text-blue-600"
              style={{ padding: 0 }}
              onClick={() => onEdit(true)}
            >
              Edit
            </button>

            <Popconfirm
              cancelText="No"
              okText="Yes"
              onConfirm={async () => {
                if (props.parentEl) {
                  await props.onDelete(comment.uuid, props.parentEl.uuid);
                } else {
                  await props.onDelete(comment.uuid);
                }

                const quill = editorText.current?.getQuill();

                if (editorText.current && quill?.editor) {
                  quill.setEditorContents(quill.editor, deletedMessage);
                }
              }}
              placement="bottom"
              title="Do you want to delete this comment?"
            >
              <button
                type="button"
                className="text-red-500"
                style={{ padding: 0 }}
              >
                Delete
              </button>
            </Popconfirm>
          </>
        ) : undefined}
      </Space>

      <div className="mt-1">
        <ReactionCounter counters={comment.reactions} />
      </div>
    </>
  );
};

export default CommentActions;
