import { Context, FC, ReactNode, useContext, useEffect } from 'react';
import InfiniteScrollApi from 'src/components/InfiniteScrollApi';
import { InfinityScrollContext } from 'src/components/InfiniteScrollApi/createInfinityScrollApiContext';
import { Notification } from './DropdownCard';

interface InfinityScrollProps {
  context: Context<InfinityScrollContext<Notification>>;
  menuItems: ReactNode;
}

const DropdownBody: FC<InfinityScrollProps> = ({ context, menuItems }) => {
  const { initialFetch, fetchMoreItems } = useContext(context);

  useEffect(() => {
    if (!initialFetch) {
      fetchMoreItems();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [initialFetch]);

  return (
    <div id="bell-notification--dropdown-body" className="bl-dropdown-body">
      <InfiniteScrollApi
        scrollableTarget="bell-notification--dropdown-body"
        context={context}
      >
        {menuItems}
      </InfiniteScrollApi>
    </div>
  );
};

export default DropdownBody;
