import { createSlice } from '@reduxjs/toolkit';
import { ApiResponse, TaskResponse } from 'src/types';

export interface TasksStateType {
  loading: boolean;
  data: ApiResponse<TaskResponse> | null;
  error: null | string;
}

const initialState: TasksStateType = {
  loading: false,
  data: null,
  error: null,
};

export const projectTasksSlice = createSlice({
  name: 'tasks',
  initialState,
  reducers: {
    getProjectTasks: () => {},
    setProjectTasksLoading: (state) => ({ ...state, loading: true }),
    setProjectTasksData: (state, action) => ({
      ...state,
      loading: false,
      data: action.payload,
    }),
    clearProjectTasksData: () => initialState,
    setProjectTasksError: (state, action) => ({
      ...state,
      loading: false,
      error: action.payload,
    }),
  },
});

export const {
  setProjectTasksData,
  setProjectTasksError,
  setProjectTasksLoading,
  getProjectTasks,
  clearProjectTasksData,
} = projectTasksSlice.actions;

export default projectTasksSlice.reducer;
