import Card from 'src/components/Card';
import Box from 'src/components/Box';
import listRequests from './listRequests.json';

const RequestPage = () => {
  return (
    <Box>
      <h1 className="mb-2 text-xl font-light text-blue-700 lg:text-2xl">
        Select a request
      </h1>

      <div className="grid grid-flow-row gap-4 sm:grid-cols-2 lg:grid-cols-3">
        {listRequests.map((request) => (
          <Card
            key={request.name}
            name={request.name}
            category=""
            snippet=""
            link={`/request/${request.id}`}
            imageUrl={request.imageUrl}
            buttonName="Request"
          />
        ))}
      </div>
    </Box>
  );
};

export default RequestPage;
