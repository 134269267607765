const allStatus = [
  { label: 'Todo', value: 'todo' },
  { label: 'In Progress', value: 'in_progress' },
  { label: 'Waiting approval', value: 'waiting_approval' },
  { label: 'Working On Now', value: 'working_on_now' },
  { label: 'Pending', value: 'pending' },
  { label: 'Approved', value: 'approved' },
  { label: 'Completed', value: 'completed' },
  { label: 'Cancelled', value: 'cancelled' },
  { label: 'Needs attention', value: 'needs_attention' },
];

const findStatus = (status: string) => {
  return (
    allStatus.find((item) => item.value === status) ?? {
      label: status,
      value: status,
    }
  );
};

const getTaskStatusOptions = (
  status: string | null = null,
  approvalTarget: boolean = false
) => {
  if (!status) {
    return allStatus;
  }

  if (approvalTarget) {
    return [
      findStatus(status),
      { label: 'Approved', value: 'approved' },
      { label: 'Request changes', value: 'request_changes' },
      { label: 'Cancel', value: 'rejected' },
    ];
  }

  if (status === 'waiting_approval') {
    return [
      { label: 'Waiting approval', value: 'waiting_approval' },
      { label: 'Approved', value: 'approved' },
    ];
  }

  if (status === 'pending') {
    return [
      { label: 'Pending', value: 'pending' },
      { label: 'Completed', value: 'completed' },
    ];
  }

  return [findStatus(status)];
};

export default getTaskStatusOptions;
