import { AxiosResponse } from 'axios';
import { call, put, StrictEffect, takeLatest } from 'redux-saga/effects';
import apiRequests from 'src/utils/api';
import apiRoutes from 'src/utils/apiRoutes';

import {
  getPendingTasks,
  setPendingTasksData,
  setPendingTasksError,
  setPendingTasksLoading,
} from './reducer';
import asyncErrorHandler from 'src/utils/asyncErrorHandler';

const { get } = apiRequests;

function* getPendingTasksSagaListener() {
  try {
    yield put(setPendingTasksLoading());
    const res: AxiosResponse = yield call(get, apiRoutes.PROJECT_TASKS, {
      'filters[task_status]': ['waiting_approval', 'pending'],
    });

    yield put(setPendingTasksData(res.data));
  } catch (error) {
    yield put(setPendingTasksError(error));
    asyncErrorHandler(error);
  }
}

function* pendingTasksSaga(): Generator<StrictEffect> {
  yield takeLatest(getPendingTasks.type, getPendingTasksSagaListener);
}

export default pendingTasksSaga;
