import { Form, Select } from 'antd';
import { FC, useMemo } from 'react';

interface DropdownProps {
  question: any;
  questions: any[];
  disabled: boolean;
}

const Dropdown: FC<DropdownProps> = ({ question, questions, disabled }) => {
  const options = useMemo(() => {
    return questions
      .filter(
        (item) => item.type === 'DropdownOption' && item.parent === question.id
      )
      .map((item) => ({ label: item.label, value: item.label }));
  }, [question.id, questions]);

  return (
    <Form.Item
      label={question.label}
      name={question.id}
      rules={[
        { required: question.required, message: 'This field is required' },
      ]}
    >
      <Select
        className={disabled ? 'read-only' : undefined}
        options={options}
        allowClear
      />
    </Form.Item>
  );
};

export default Dropdown;
