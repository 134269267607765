import { Form, Input } from 'antd';
import { FC } from 'react';

const { TextArea } = Input;

interface ParagraphProps {
  question: any;
  disabled: boolean;
}

const Paragraph: FC<ParagraphProps> = ({ question, disabled }) => {
  return (
    <Form.Item
      label={question.label}
      name={question.id}
      rules={[
        { required: question.required, message: 'This field is required' },
      ]}
    >
      <TextArea style={{ minHeight: 150 }} readOnly={disabled} />
    </Form.Item>
  );
};

export default Paragraph;
