import { ReactNode } from 'react';
import { GoogleReCaptchaProvider } from 'react-google-recaptcha-v3';
import environments from 'src/utils/environments';

const ReCaptchaProvider = ({ children }: { children: ReactNode }) => {
  return (
    <GoogleReCaptchaProvider reCaptchaKey={environments.GOOGLE_RECAPTCHA_KEY}>
      {children}
    </GoogleReCaptchaProvider>
  );
};

export default ReCaptchaProvider;
