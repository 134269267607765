import { AxiosResponse } from 'axios';
import { call, put, StrictEffect, takeLatest } from 'redux-saga/effects';
import apiRequests from 'src/utils/api';
import apiRoutes from 'src/utils/apiRoutes';
import asyncErrorHandler from 'src/utils/asyncErrorHandler';
import {
  getProjectAdvertising,
  setProjectAdvertisingData,
  setProjectAdvertisingLoading,
  setProjectAdvertisingError,
} from './reducer';

const { get } = apiRequests;

function* getProjectAdvertisingSagaListener(action: any) {
  try {
    yield put(setProjectAdvertisingLoading());

    const res: AxiosResponse = yield call(get, apiRoutes.PROJECTS, {
      'filters[projects.status]': [
        'paused',
        'waiting_to_launch',
        'in_progress',
        'active',
      ],
      'filters[advertising][]': 'true',
      'page[size]': 1,
      'page[number]': 1,
    });

    yield put(
      setProjectAdvertisingData(res.data.data.length ? res.data.data[0] : null)
    );
  } catch (error) {
    yield put(setProjectAdvertisingError(error));
    asyncErrorHandler(error);
  }
}

function* projectAdvertisingSaga(): Generator<StrictEffect> {
  yield takeLatest(
    getProjectAdvertising.type,
    getProjectAdvertisingSagaListener
  );
}

export default projectAdvertisingSaga;
