import { AxiosResponse } from 'axios';
import {
  call,
  put,
  select,
  StrictEffect,
  takeLatest,
} from 'redux-saga/effects';
import {
  getWorkLogs,
  getWorkLogsTotalTime,
  setWorkLogsData,
  setWorkLogsError,
  setWorkLogsFilters,
  setWorkLogsLoading,
  setWorkLogsTotalTime,
  setWorkLogsTotalTimeLoading,
} from './reducer';
import apiRequests from 'src/utils/api';
import apiRoutes from 'src/utils/apiRoutes';
import { RootState } from '../index';

const { get } = apiRequests;

function* getWorkLogsSagaListener(): any {
  const workLogs: RootState['workLogs'] = yield select(
    (state) => state.workLogs
  );
  const project: any = yield select((state) => state.projectRetainer.data);

  if (!project) {
    return;
  }

  const searchTerm =
    workLogs.filters.date_start && workLogs.filters.date_end
      ? {
          'search_term[0][0][rule]': 'greater_than_or_equals',
          'search_term[0][0][field]': 'date',
          'search_term[0][0][value]': workLogs.filters.date_start,
          'search_term[0][1][rule]': 'less_than_or_equals',
          'search_term[0][1][field]': 'date',
          'search_term[0][1][value]': workLogs.filters.date_end,
        }
      : {};

  try {
    yield put(setWorkLogsLoading());

    const res: AxiosResponse = yield call(get, apiRoutes.PROJECT_WORK_LOGS, {
      'sort[by]': 'date',
      'sort[direction]': 'desc',
      'page[size]': 1000,
      'filters[project.uuid][]': project.uuid,
      ...searchTerm,
    });

    yield put(setWorkLogsData(res.data));
  } catch (error) {
    yield put(setWorkLogsError(error));
  }
}

function* getWorkLogsTotalTimeSagaListener(): any {
  const project: any = yield select((state) => state.projectRetainer.data);

  if (!project) {
    return;
  }

  try {
    yield put(setWorkLogsTotalTimeLoading());

    const res: AxiosResponse = yield call(get, apiRoutes.PROJECT_WORK_LOGS, {
      'page[size]': 1000,
      'filters[project.uuid][]': project.uuid,
    });

    yield put(setWorkLogsTotalTime(parseInt(res.data.total_time ?? 0)));
  } catch (error) {}
}

function* workLogsSaga(): Generator<StrictEffect> {
  yield takeLatest(getWorkLogsTotalTime.type, getWorkLogsTotalTimeSagaListener);
  yield takeLatest(getWorkLogs.type, getWorkLogsSagaListener);
  yield takeLatest(setWorkLogsFilters.type, getWorkLogsSagaListener);
}

export default workLogsSaga;
