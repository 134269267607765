export const logoutUser = () => {
  removeToken();
  // @ts-ignore
  window.location = '/login';
};

export const getToken = () => {
  return localStorage ? localStorage.getItem('auth_token') ?? null : null;
};

export const saveToken = (token: string) => {
  if (localStorage) {
    localStorage.setItem('auth_token', token);
  }
};

export const removeToken = () => {
  if (localStorage) {
    localStorage.removeItem('auth_token');
  }
};
