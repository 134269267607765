import { Button, Form, Input, notification } from 'antd';
import React, { useState } from 'react';
import { AiFillSmile, AiOutlineUser } from 'react-icons/ai';
import { MdOutlinePhoneIphone } from 'react-icons/md';
import { TbLock, TbMail } from 'react-icons/tb';
import { Link, useNavigate } from 'react-router-dom';
import apiRequests from 'src/utils/api';
import apiRoutes from 'src/utils/apiRoutes';
import asyncErrorHandler from 'src/utils/asyncErrorHandler';

interface RegisterFormProps {
  email: string;
  customerUuid: string;
}

const RegisterForm: React.FC<RegisterFormProps> = ({ email, customerUuid }) => {
  const [formLoading, setFormLoading] = useState(false);
  const navigate = useNavigate();

  const submit = async (values: any) => {
    try {
      setFormLoading(true);

      values.customer_id = customerUuid;

      await apiRequests.post(apiRoutes.CONTACT_REGISTER, values);

      notification.open({
        message: 'Sign up completed successfully!',
        description:
          'Congratulations! Your sign up process has been completed successfully and now you can log in to the system.',
        icon: <AiFillSmile color="#44D62C" />,
        duration: 0,
      });

      navigate('/login');
    } catch (error: any) {
      asyncErrorHandler(error);
    } finally {
      setFormLoading(false);
    }
  };

  return (
    <Form
      onFinish={submit}
      autoComplete="off"
      size="large"
      validateTrigger={['onSubmit', 'onBlur']}
      initialValues={{ email: email }}
    >
      <h1 className="mb-4 text-center text-2xl font-light">Register</h1>

      <Form.Item
        name="email"
        rules={[
          {
            required: true,
            message: 'Please input your email!',
            type: 'email',
          },
        ]}
      >
        <Input disabled prefix={<TbMail />} placeholder="Email" />
      </Form.Item>

      <Form.Item
        name="name"
        rules={[
          {
            required: true,
            message: 'Please input your name!',
          },
        ]}
      >
        <Input prefix={<AiOutlineUser />} placeholder="Name" />
      </Form.Item>

      <Form.Item name="phone">
        <Input prefix={<MdOutlinePhoneIphone />} placeholder="Phone" />
      </Form.Item>

      <Form.Item
        name="password"
        hasFeedback
        rules={[
          {
            required: true,
            message: 'Please input your password!',
          },
          {
            min: 6,
          },
        ]}
      >
        <Input.Password
          prefix={<TbLock />}
          placeholder="Password"
          autoComplete="new-password"
        />
      </Form.Item>

      <Form.Item
        name="confirm_password"
        dependencies={['password']}
        hasFeedback
        rules={[
          {
            required: true,
            message: 'Please confirm your password!',
          },
          ({ getFieldValue }) => ({
            validator(_, value) {
              if (!value || getFieldValue('password') === value) {
                return Promise.resolve();
              }
              return Promise.reject(
                new Error(
                  'The confirmation password that you entered do not match!'
                )
              );
            },
          }),
        ]}
      >
        <Input.Password
          prefix={<TbLock />}
          placeholder="Confirm password"
          autoComplete="new-password"
        />
      </Form.Item>

      <Form.Item>
        <Button
          loading={formLoading}
          type="primary"
          shape="round"
          htmlType="submit"
          block
        >
          Register
        </Button>
      </Form.Item>

      <div className="text-right">
        <Link to="/login">Already have account? Login.</Link>
      </div>

      <div className="mt-6 text-gray-600">
        By submitting this form and signing up for texts, you consent to receive
        text messages from Lionshare. Consent is not a condition of purchase.
        Msg & data rates may apply. Msg frequency varies. Unsubscribe at any
        time by replying STOP or disabling SMS notifications in the notification
        options in your profile on Lionshare
      </div>
    </Form>
  );
};

export default RegisterForm;
