import { createSlice } from '@reduxjs/toolkit';
import { ApiResponse } from 'src/types';

export interface ContactsStateType {
  loading: boolean;
  data: ApiResponse<any> | null;
  error: null | string;
}

const initialState: ContactsStateType = {
  loading: false,
  data: null,
  error: null,
};

export const contactsSlice = createSlice({
  name: 'contacts',
  initialState,
  reducers: {
    getContacts: () => {},
    setContactsLoading: (state) => ({ ...state, loading: true }),
    setContactsData: (state, action) => ({
      ...state,
      loading: false,
      data: action.payload,
    }),
    updateContact: (
      state,
      {
        payload,
      }: {
        payload: Record<string, any>;
        type: string;
      }
    ) => ({
      ...state,
      loading: false,
      data: state.data
        ? {
            ...state.data,
            data: state.data.data.map((item) =>
              item.uuid === payload.uuid ? { ...item, ...payload } : item
            ),
          }
        : null,
    }),
    removeContact: (state, { payload }: { payload: string; type: string }) => ({
      ...state,
      loading: false,
      data: state.data
        ? {
            ...state.data,
            data: state.data.data.filter((item) => item.uuid !== payload),
          }
        : null,
    }),
    clearContactsData: () => initialState,
    setContactsError: (state, action) => ({
      ...state,
      loading: false,
      error: action.payload,
    }),
  },
});

export const {
  setContactsData,
  updateContact,
  removeContact,
  setContactsError,
  setContactsLoading,
  getContacts,
  clearContactsData,
} = contactsSlice.actions;

export default contactsSlice.reducer;
