import { FC } from 'react';
import ENVIRONMENT from 'src/utils/environments';

const imageExtensions = [
  'jpg',
  'jpeg',
  'png',
  'gif',
  'tif',
  'svg',
  'bmp',
  'psd',
  'tiff',
  'ai',
  'lsm',
];

interface PreviewFileCardProps {
  file: Record<string, any>;
}

const PreviewFileCard: FC<PreviewFileCardProps> = ({ file }) => {
  if (imageExtensions.includes(file.extension)) {
    return (
      <img
        src={`${ENVIRONMENT.REACT_APP_UPLOADS_PATH}/${file.uuid}.${file.extension}`}
        alt="render"
        style={{
          maxWidth: '100%',
          height: 'auto',
        }}
      />
    );
  }

  return (
    <iframe
      title="render file"
      src={`${ENVIRONMENT.REACT_APP_UPLOADS_PATH}/${file.uuid}.${file.extension}`}
      style={{
        width: '100%',
        height: 'calc(100vh - 105px)',
      }}
    />
  );
};

export default PreviewFileCard;
