import { createSlice } from '@reduxjs/toolkit';
import { ApiResponse, InboxRelatedUser, TaskResponse } from 'src/types';

export interface TasksStateType {
  loading: boolean;
  data: ApiResponse<TaskResponse> | null;
  error: null | string;
  showCompleted: boolean;
}

const initialState: TasksStateType = {
  loading: false,
  data: null,
  error: null,
  showCompleted: false,
};

export const dashboardTasksSlice = createSlice({
  name: 'dashboardTasks',
  initialState,
  reducers: {
    getDashboardTasks: (
      state,
      action: { payload: Record<string, any>; type: string }
    ) => {},
    setDashboardTasksLoading: (state) => ({ ...state, loading: true }),
    setDashboardTasksData: (state, action) => ({
      ...state,
      loading: false,
      data: action.payload,
    }),
    clearDashboardTasksData: () => initialState,
    setDashboardTasksError: (state, action) => ({
      ...state,
      loading: false,
      error: action.payload,
    }),
    setDashboardTaskCompleted: (
      state,
      action: { payload: boolean; type: string }
    ) => ({
      ...state,
      showCompleted: action.payload,
    }),
    updateDashboardTaskCommentRelatedUserMention: (
      state,
      {
        payload,
      }: {
        payload: {
          uuid: string;
          newValues: Partial<InboxRelatedUser>;
          user: any;
        };
        type: string;
      }
    ) =>
      state.data
        ? {
            ...state,
            data: {
              ...state.data,
              data: state.data.data.map((item) =>
                item.uuid === payload.uuid && item.comment_latest
                  ? {
                      ...item,
                      comment_latest: {
                        ...item.comment_latest,
                        related_users: item.comment_latest.related_users.map(
                          (relatedUser) =>
                            relatedUser.uuid !== payload.user.uuid
                              ? relatedUser
                              : { ...relatedUser, ...payload.newValues }
                        ),
                      },
                    }
                  : item
              ),
            },
          }
        : state,
  },
});

export const {
  setDashboardTasksData,
  setDashboardTasksError,
  setDashboardTasksLoading,
  getDashboardTasks,
  clearDashboardTasksData,
  setDashboardTaskCompleted,
  updateDashboardTaskCommentRelatedUserMention,
} = dashboardTasksSlice.actions;

export default dashboardTasksSlice.reducer;
