import { Form, Checkbox as CheckboxAntd } from 'antd';
import { FC, useMemo } from 'react';

interface CheckboxProps {
  question: any;
  questions: any[];
  disabled: boolean;
}

const Checkbox: FC<CheckboxProps> = ({ question, questions, disabled }) => {
  const options = useMemo(() => {
    return questions
      .filter((item) => item.parent === question.id)
      .map((item) => ({ label: item.label, value: item.label }));
  }, [question.id, questions]);

  return (
    <Form.Item
      label={question.label}
      name={question.id}
      rules={[
        { required: question.required, message: 'This field is required' },
      ]}
    >
      <CheckboxAntd.Group
        className={disabled ? 'read-only' : undefined}
        options={options}
      />
    </Form.Item>
  );
};

export default Checkbox;
