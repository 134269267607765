import { Form, Input, Modal, message } from 'antd';
import { FC, useState } from 'react';
import { TbLock } from 'react-icons/tb';
import { useSelector } from 'react-redux';
import apiRequests from 'src/utils/api';
import apiRoutes from 'src/utils/apiRoutes';
import asyncErrorHandler from 'src/utils/asyncErrorHandler';
import { RootState } from 'src/store';

interface ResetPasswordModalProps {
  visible?: boolean;
  onClose?: any;
}

const ResetPasswordModal: FC<ResetPasswordModalProps> = ({
  visible,
  onClose,
}) => {
  const [form] = Form.useForm();
  const [state, setState] = useState({
    saving: false,
  });
  const user = useSelector((globalState: RootState) => globalState.auth.user);
  const { saving } = state;

  const savePassword = async () => {
    try {
      const values = await form.validateFields();

      if (values?.password !== values?.password_confirm) {
        throw new Error('Passwords do not match.');
      }

      setState((prevState) => ({ ...prevState, saving: true }));
      await apiRequests.put(
        `${apiRoutes.CONTACTS}/${user?.contact?.uuid}`,
        values
      );
      setState((prevState) => ({ ...prevState, saving: false }));
      message.success('Password updated.');
      onClose();
    } catch (error) {
      asyncErrorHandler(error);
      setState((prevState) => ({ ...prevState, saving: false }));
    }
  };

  return (
    <Modal
      destroyOnClose
      confirmLoading={saving}
      title="Reset password"
      open={visible}
      onCancel={onClose}
      onOk={savePassword}
      afterClose={() => {
        form.resetFields();
      }}
    >
      <div className="mt-[20px]"></div>
      <Form form={form} layout="vertical">
        <Form.Item
          label="Password"
          name="password"
          rules={[
            {
              required: true,
              message: 'Please input your password!',
            },
          ]}
        >
          <Input.Password prefix={<TbLock />} placeholder="Password" />
        </Form.Item>
        <Form.Item
          label="Confirm password"
          name="password_confirm"
          rules={[
            {
              required: true,
              message: 'Please input your password!',
            },
          ]}
        >
          <Input.Password prefix={<TbLock />} placeholder="Confirm password" />
        </Form.Item>
      </Form>
    </Modal>
  );
};

export default ResetPasswordModal;
