import { ErrorBoundary } from '@sentry/react';
import { Result } from 'antd';
import { Outlet } from 'react-router-dom';

const ErrorBoundaryRoute = () => {
  return (
    <ErrorBoundary
      fallback={
        <Result
          status="warning"
          title="Something went wrong. Please reload the page."
        />
      }
    >
      <Outlet />
    </ErrorBoundary>
  );
};

export default ErrorBoundaryRoute;
