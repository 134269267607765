/* eslint-disable react/jsx-no-target-blank */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useLayoutEffect, useState } from 'react';
import {
  Alert,
  Button,
  Checkbox,
  Form,
  Input,
  Tabs,
  notification,
  TabsProps,
} from 'antd';
import { TbLock, TbMail } from 'react-icons/tb';
import { AiFillSmile } from 'react-icons/ai';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import RequestAccessModal from 'src/components/RequestAccessModal';
import { RootState } from 'src/store';
import { login } from 'src/store/auth/reducer';
import apiRoutes from 'src/utils/apiRoutes';
import apiRequests from 'src/utils/api';
import ENVIRONMENT from 'src/utils/environments';
import asyncErrorHandler from 'src/utils/asyncErrorHandler';
import LionshareSVG from 'src/icons/LionshareSvg';

const Login: React.FC = () => {
  const [state, setState] = useState({
    loading: false,
    showSavageError: false,
  });
  const [openRequestAccessModal, setOpenRequestAccessModal] = useState(false);
  const { showSavageError } = state;
  const navigate = useNavigate();
  const loadingLogin = useSelector(
    (globalState: RootState) => globalState.auth.loadingLogin
  );
  const dispatch = useDispatch();
  const [form] = Form.useForm();
  const [loginForm] = Form.useForm();
  const [tab, setTab] = useState('1');

  const args = {
    message: 'Reset password link',
    description:
      'We have sent the reset link to your mail. Please use it to reset your password.',
    icon: <AiFillSmile color="#44D62C" />,
    duration: 0,
  };

  const onFinish = (values: any) => {
    if (values?.email?.endsWith('@savageglobalent.com')) {
      setState((prevState) => ({
        ...prevState,
        showSavageError: true,
      }));
      loginForm.resetFields();
      return;
    }

    dispatch(login(values));
  };

  const handleSendResetEmail = async (values: any) => {
    try {
      setState((prevState) => ({
        ...prevState,
        loading: true,
        showSavageError: false,
      }));
      await apiRequests.post(apiRoutes.RESET_EMAIL, values);
      notification.open(args);
      navigate('/login');
      form.resetFields();
      setTab('2');
      setState((prevState) => ({ ...prevState, loading: false }));
    } catch (error: any) {
      asyncErrorHandler(error);
      setState((prevState) => ({ ...prevState, loading: false }));
    }
  };

  const onFinishFailed = (errorInfo: any) => {
    asyncErrorHandler(errorInfo);
  };

  const onRequestAccessClick = () => {
    setOpenRequestAccessModal(true);
  };

  const tabItems: TabsProps['items'] = [
    {
      key: '1',
      label: 'Login',
      children: (
        <div className="min-h-[300px] w-[220px]">
          {showSavageError && (
            <Alert
              type="error"
              className="!mb-[20px]"
              message={
                <p>
                  Hey this is the app for clients. Please go to{' '}
                  <a
                    target="_blank"
                    rel="noreferrer"
                    href={ENVIRONMENT.ADMIN_URL}
                  >
                    {ENVIRONMENT.ADMIN_URL}
                  </a>
                </p>
              }
            />
          )}

          <Form
            form={loginForm}
            name="login"
            onFinish={onFinish}
            onFinishFailed={onFinishFailed}
            autoComplete="off"
            size="large"
            validateTrigger={['onSubmit', 'onBlur']}
          >
            <Form.Item
              name="email"
              rules={[
                {
                  required: true,
                  message: 'Please input your email!',
                  type: 'email',
                },
              ]}
            >
              <Input
                prefix={<TbMail />}
                placeholder="Email"
                className="w-full"
              />
            </Form.Item>

            <Form.Item
              name="password"
              rules={[
                {
                  required: true,
                  message: 'Please input your password!',
                },
              ]}
            >
              <Input.Password prefix={<TbLock />} placeholder="Password" />
            </Form.Item>

            <Form.Item valuePropName="checked">
              <Checkbox>Remember me</Checkbox>
            </Form.Item>

            <Form.Item>
              <Button
                loading={loadingLogin}
                type="primary"
                shape="round"
                htmlType="submit"
                block
              >
                Submit
              </Button>
            </Form.Item>

            <Form.Item>
              <Button
                type="link"
                shape="round"
                onClick={onRequestAccessClick}
                block
              >
                Request access
              </Button>
            </Form.Item>
          </Form>
        </div>
      ),
    },
    {
      key: '2',
      label: 'Reset password',
      children: (
        <div className="min-h-[300px] w-[220px]">
          <Form
            form={form}
            name="resetpassword"
            onFinish={handleSendResetEmail}
            autoComplete="off"
            size="large"
            validateTrigger={['onSubmit', 'onBlur']}
          >
            <Form.Item
              name="email"
              rules={[
                {
                  required: true,
                  message: 'Please input your email!',
                  type: 'email',
                },
              ]}
            >
              <Input prefix={<TbMail />} placeholder="Email" />
            </Form.Item>

            <Form.Item>
              <Button
                loading={state?.loading}
                type="primary"
                shape="round"
                htmlType="submit"
                block
              >
                Get new password
              </Button>
            </Form.Item>
          </Form>
        </div>
      ),
    },
  ];

  useLayoutEffect(() => {
    if (!loadingLogin) {
      loginForm.setFieldValue('password', '');
    }
  }, [loadingLogin]);

  return (
    <div className="flex h-screen w-full flex-col md:flex-row">
      <RequestAccessModal
        open={openRequestAccessModal}
        onClose={() => setOpenRequestAccessModal(false)}
      />

      <div className="flex w-full flex-col items-center justify-center bg-[#0044cc] p-4 sm:h-full sm:p-2">
        <div className="max-w-[140px] md:max-w-[440px]">
          <LionshareSVG />
        </div>

        <div className="hidden max-w-[400px] pt-6 sm:block">
          <p className=" px-4 font-light text-white  md:text-[18px]">
            Our all-in-one solution that connects you with our team from any
            device, providing a request system, real-time notifications,
            resources, and more.
          </p>
        </div>
      </div>

      <div className="flex h-full w-full flex-col items-center justify-center ">
        <Tabs
          defaultActiveKey="1"
          activeKey={tab}
          items={tabItems}
          size="large"
          centered
          onChange={setTab}
        />
      </div>
    </div>
  );
};

export default Login;
