import { FC, useMemo } from 'react';
import DownloadFileCard from './DownloadFileCard';
import PreviewFileCard from './PreviewFileCard';

const imagePrefixList = [
  'jpg',
  'jpeg',
  'png',
  'gif',
  'tif',
  'svg',
  'bmp',
  'psd',
  'tiff',
  'ai',
  'lsm',
  'pdf',
  'json',
];

interface FileViewerBodyProps {
  file: Record<string, any>;
  downloading: boolean;
  onDownload: () => void;
}

const FileViewerBody: FC<FileViewerBodyProps> = ({
  file,
  downloading,
  onDownload,
}) => {
  const isPreview = useMemo(() => {
    return imagePrefixList.includes(file.extension);
  }, [file]);

  return (
    <div className="flex min-h-[calc(100vh-64px)] items-center justify-center p-6">
      {isPreview ? (
        <PreviewFileCard file={file} />
      ) : (
        <DownloadFileCard downloading={downloading} onDownload={onDownload} />
      )}
    </div>
  );
};

export default FileViewerBody;
