import { FC } from 'react';

interface LionshareSVGProps {}

const LionshareSVG: FC<LionshareSVGProps> = () => {
  return (
    <svg
      width="100%"
      viewBox="0 0 732 153"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M37.9867 148.801C27.5867 148.801 26.5867 146.601 26.5867 133.801V0.800781H23.9867C23.3867 0.800781 22.9867 1.00078 22.5867 1.20079C19.3867 2.80078 16.1867 4.40078 12.3867 5.60078C8.98673 7.00078 4.98673 8.00078 0.586734 8.80079C-0.213266 9.00078 -0.0132661 10.4008 0.586734 10.4008C10.7867 8.60079 12.3867 9.00078 12.3867 24.0008V134.001C12.3867 135.001 12.3867 136.601 12.3867 138.001C12.1867 147.001 10.3867 148.801 1.18673 148.801C0.786734 148.801 0.786734 150.001 1.18673 150.001C3.98673 150.001 8.38673 150.001 12.3867 150.001H26.5867C30.3867 150.001 34.7867 150.001 37.9867 150.001C38.3867 150.001 38.3867 148.801 37.9867 148.801Z"
        fill="#ffffff"
      />
      <path
        d="M59.4586 37.6008C64.8586 37.6008 69.2586 33.2008 69.2586 27.8008C69.2586 22.4008 64.8586 17.8008 59.4586 17.8008C53.8586 17.8008 49.6586 22.4008 49.6586 27.8008C49.6586 33.2008 53.8586 37.6008 59.4586 37.6008ZM77.6586 149.001C69.0586 149.001 67.0586 147.401 66.6586 139.201V55.4008H64.0586C63.4586 55.4008 63.0586 55.4008 62.6586 55.6008C59.4586 57.2008 56.2586 58.8008 52.4586 60.2008C52.4586 60.2008 52.4586 60.2008 52.2586 60.2008C48.8586 61.4008 44.8586 62.6008 40.6586 63.2008C39.8586 63.4008 40.0586 64.8008 40.6586 64.8008C48.8586 63.4008 51.6586 63.4008 52.2586 71.6008V133.801C52.2586 146.601 51.4586 149.001 40.8586 149.001C40.6586 149.001 40.4586 150.201 40.8586 150.201C46.2586 150.201 57.0586 150.201 59.8586 150.201C62.6586 150.201 72.2586 150.201 77.6586 150.201C78.0586 150.201 78.0586 149.001 77.6586 149.001Z"
        fill="#ffffff"
      />
      <path
        d="M126.777 53.8008C98.5774 53.8008 78.9774 73.8008 78.9774 102.601C78.9774 132.001 97.5774 151.001 126.777 151.001C159.777 151.001 174.577 126.601 174.577 102.401C174.577 73.8008 154.977 53.8008 126.777 53.8008ZM126.577 149.601C104.577 149.601 92.7774 125.201 92.7774 103.001C92.7774 80.4008 106.177 55.0008 127.377 55.0008C148.777 55.0008 160.377 79.8008 160.377 102.401C160.377 125.001 148.377 149.601 126.577 149.601Z"
        fill="#ffffff"
      />
      <path
        d="M289.41 148.801C279.21 148.801 277.81 146.601 277.81 134.201V92.2008C277.81 68.6008 265.41 53.6008 240.41 53.6008C221.61 53.6008 206.81 65.6008 201.21 93.0008V55.4008H199.21C199.01 55.4008 199.01 55.4008 198.81 55.4008C198.41 55.4008 198.21 55.6008 197.81 55.8008C194.61 57.4008 191.41 58.8008 187.81 60.2008H187.61C184.01 61.4008 180.21 62.6008 175.81 63.4008C175.01 63.6008 175.21 64.8008 175.81 64.8008C185.41 63.2008 187.61 63.4008 187.61 76.4008C187.61 77.2008 187.61 77.8008 187.61 78.6008H187.81V133.601C187.81 146.401 187.01 148.801 176.41 148.801C176.01 148.801 176.01 150.001 176.41 150.001C179.21 150.001 183.81 150.001 187.81 150.001H201.41C205.41 150.001 210.01 150.001 213.21 150.001C213.61 150.001 213.61 148.801 213.21 148.801C202.81 148.801 201.41 146.601 201.41 133.801V95.0008C207.41 71.4008 219.41 60.8008 235.41 60.8008C256.81 60.8008 264.01 76.4008 264.01 96.0008V137.201C263.81 147.001 262.21 148.801 252.61 148.801C252.41 148.801 252.21 150.001 252.61 150.001C258.01 150.001 268.81 150.001 271.61 150.001C274.41 150.001 284.01 150.001 289.41 150.001C290.01 150.001 289.81 148.801 289.41 148.801Z"
        fill="#ffffff"
      />
      <path
        d="M346.473 100.601L322.473 87.6008C301.673 76.0008 301.273 55.6008 327.273 54.0008C353.273 52.6008 357.273 73.8008 359.073 87.4008C359.073 87.4008 359.273 87.6008 359.873 87.6008C360.473 87.6008 360.273 87.4008 360.273 87.4008V62.8008C360.273 62.8008 354.273 52.6008 326.873 52.6008C302.673 52.6008 296.473 65.2008 296.473 65.2008C288.673 79.0008 293.473 89.4008 316.073 103.001L340.073 115.801C358.073 126.201 364.473 149.801 335.273 150.801C298.273 152.401 297.073 127.201 295.473 111.601C295.473 111.601 295.273 111.401 294.873 111.401C294.073 111.401 294.073 111.801 294.073 111.801V140.201C294.073 140.201 306.273 152.401 335.073 152.401C355.873 152.401 363.473 142.601 363.473 142.601C374.673 128.601 368.073 112.201 346.473 100.601Z"
        fill="#ffffff"
      />
      <path
        d="M483.718 149.001C473.518 149.001 472.118 146.801 472.118 134.401V92.4008C472.118 68.8008 459.718 53.6008 434.718 53.6008C415.918 53.6008 401.118 65.6008 395.518 93.2008V1.20079H393.518C393.318 1.20079 393.318 1.20079 393.118 1.20079C392.718 1.20079 392.518 1.40079 392.118 1.40079C388.918 3.00079 385.718 4.60078 382.118 6.00079H381.918C378.318 7.20078 374.518 8.40079 370.118 9.00078C369.318 9.20078 369.518 10.6008 370.118 10.6008C379.718 9.00078 381.918 9.20078 381.918 22.2008C381.918 22.8008 381.918 23.6008 381.918 24.2008H382.118V133.801C382.118 146.601 381.318 148.801 370.718 148.801C370.318 148.801 370.318 150.201 370.718 150.201C373.518 150.201 378.118 150.201 382.118 150.201H395.718C399.718 150.201 404.318 150.201 407.518 150.201C407.918 150.201 407.918 148.801 407.518 148.801C397.118 148.801 395.718 146.601 395.718 134.001V95.2008C401.718 71.6008 413.718 61.0008 429.718 61.0008C451.118 61.0008 458.318 76.6008 458.318 96.2008V137.401C458.118 147.001 456.518 149.001 446.918 149.001C446.718 149.001 446.518 150.201 446.918 150.201C452.318 150.201 463.118 150.201 465.918 150.201C468.718 150.201 478.318 150.201 483.718 150.201C484.318 150.201 484.118 149.001 483.718 149.001Z"
        fill="#ffffff"
      />
      <path
        d="M576.195 148.801C566.195 148.801 564.395 146.801 564.395 135.401V92.2008C564.395 68.6008 551.995 53.6008 527.195 53.6008C509.395 53.6008 495.195 64.2008 488.995 88.4008H489.995C496.395 69.4008 507.595 61.0008 521.995 61.0008C541.395 61.0008 549.195 73.6008 550.395 90.6008C546.795 94.8008 537.595 97.0008 527.395 99.4008C521.995 100.601 515.995 102.001 510.795 103.601C504.195 105.801 498.595 108.201 494.195 112.001C489.195 116.201 486.595 121.401 486.595 129.001C486.595 140.601 493.195 151.001 511.595 151.001C529.795 151.001 544.395 136.801 550.595 116.201V136.801C550.595 146.801 548.995 148.801 539.395 148.801C538.995 148.801 538.995 150.201 539.395 150.201C544.795 150.201 555.395 150.201 558.195 150.201C560.995 150.201 570.795 150.201 576.195 150.201C576.595 150.201 576.595 148.801 576.195 148.801ZM517.595 140.601C503.795 140.601 496.995 134.001 496.995 124.401C496.995 109.401 507.395 106.001 526.395 101.601C536.995 99.4008 546.395 97.2008 550.595 92.8008C550.595 93.8008 550.595 95.0008 550.595 96.0008V111.801C545.395 131.401 531.995 140.601 517.595 140.601Z"
        fill="#ffffff"
      />
      <path
        d="M603.559 94.6008V55.0008H601.559C601.359 55.0008 601.359 55.0008 601.159 55.0008C600.759 55.0008 600.559 55.2008 600.159 55.4008C596.959 57.0008 593.759 58.4008 589.959 59.8008C586.359 61.0008 582.559 62.2008 578.159 63.0008C577.359 63.2008 577.559 64.4008 578.159 64.4008C587.759 62.8008 589.959 63.0008 589.959 76.0008C589.959 76.8008 589.959 77.4008 589.959 78.0008V137.401C589.759 147.001 588.159 148.801 578.759 148.801C578.359 148.801 578.359 150.001 578.759 150.001C584.159 150.001 594.759 150.001 597.559 150.001C600.359 150.001 610.159 150.001 615.559 150.001C615.959 150.001 615.959 148.801 615.559 148.801C604.959 148.801 603.759 146.601 603.759 133.801V96.4008C609.759 73.0008 621.959 65.4008 637.959 65.4008C639.559 65.4008 641.159 65.6008 642.759 65.8008V55.0008C623.959 55.0008 609.159 67.0008 603.559 94.6008Z"
        fill="#ffffff"
      />
      <path
        d="M730.296 117.201C721.496 131.801 709.896 140.401 693.096 140.401C669.496 140.401 650.896 122.401 650.896 99.2008C650.896 96.6008 651.096 94.0008 651.296 91.6008L651.496 90.4008C651.496 89.8008 651.496 89.4008 651.696 88.8008C651.696 88.0008 652.096 86.2008 652.496 84.4008H721.296V83.8008C721.296 83.2008 721.296 82.8008 721.296 82.6008V82.2008C720.696 75.0008 718.696 69.6008 715.896 65.6008C715.896 65.6008 715.896 65.6008 715.896 65.4008C714.696 63.6008 713.096 62.0008 711.296 60.4008C711.296 60.4008 711.296 60.4008 711.096 60.4008C710.296 59.6008 709.296 58.8008 708.096 58.2008C707.896 58.0008 707.496 57.8008 707.296 57.8008C706.896 57.6008 706.696 57.4008 706.496 57.2008C702.296 55.2008 698.096 54.2008 695.096 53.8008C691.296 53.2008 685.096 53.8008 685.096 53.8008C662.296 56.2008 640.496 79.6008 640.496 106.201C640.496 122.001 646.696 135.201 657.696 143.201C664.896 148.401 673.896 151.201 684.296 151.201C693.696 151.201 702.096 148.201 709.496 143.201C709.896 143.001 710.296 142.601 710.696 142.401C719.296 136.401 726.296 127.601 731.696 118.201L730.296 117.201ZM654.096 79.4008C659.096 65.0008 670.296 57.8008 684.096 57.8008C687.296 57.8008 690.296 58.4008 692.896 59.4008C693.296 59.6008 693.696 59.8008 694.096 60.0008C694.296 60.2008 694.496 60.2008 694.696 60.2008C697.296 61.8008 700.096 64.0008 702.296 67.8008V68.0008C704.496 71.6008 705.896 76.6008 706.096 83.2008H652.896C653.096 82.0008 653.496 80.8008 653.896 79.6008C653.896 79.4008 654.096 79.4008 654.096 79.4008Z"
        fill="#ffffff"
      />
    </svg>
  );
};

export default LionshareSVG;
