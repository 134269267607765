import {
  createContext,
  ReactNode,
  useContext,
  useMemo,
  useState,
  useRef,
  useEffect,
} from 'react';
import type { TourStepProps } from 'antd';
import { Tour } from 'antd';
import { useLocation } from 'react-router-dom';

interface AppTourContextValues {
  steps: TourStepProps[];
  setStepTarget: (step: number, target: HTMLElement | null) => void;
  unsetStepTarget: (step: number) => void;
  setOpenTour: (open: boolean, step?: number) => void;
  isReady: boolean;
  subscribe: (cb: (current: number | null) => void) => () => void;
  hiddenStep: (index: number) => void;
  unhiddenStep: (index: number) => void;
}

const AppTourContext = createContext<AppTourContextValues>({
  steps: [],
  setStepTarget: () => {},
  setOpenTour: () => {},
  unsetStepTarget: () => {},
  isReady: false,
  subscribe: () => () => {},
  hiddenStep: () => {},
  unhiddenStep: () => {},
});

export default function AppTourProvider({ children }: { children: ReactNode }) {
  const [openTour, setOpenTour] = useState(false);
  const [currentStep, setCurrentStep] = useState(0);
  const [hiddenSteps, setHiddenSteps] = useState(new Set<number>());

  const { pathname } = useLocation();
  const subscribersRef = useRef<((current: number | null) => void)[]>([]);
  const subscribers = subscribersRef.current;

  const [steps, setSteps] = useState<TourStepProps[]>([
    {
      title: 'Search',
      description:
        'A global search where you can search for any task, project or form',
    },
    {
      title: 'Notifications',
      description: 'List of all the notifications you receive from the system',
    },
    {
      title: 'Invite user',
      description: 'Invite a member of your team to the system.',
    },
    {
      title: 'Resources',
      description: 'A list of useful resources',
    },
    {
      title: 'Book a call',
      description: 'Schedule a call with one of our project managers',
    },
    {
      title: 'Reset password',
      description: 'Here you can reset your password',
    },
    {
      title: 'Tasks',
      description: 'A list of all the tasks.',
      placement: 'top',
    },
    {
      title: 'My inbox',
      description:
        'Your inbox, which shows all the comments you have been mentioned in.',
      placement: 'top',
    },
    {
      title: 'Activities',
      description: 'Here you can track all comments and logs.',
      placement: 'top',
    },
  ]);

  const value = useMemo<AppTourContextValues>(() => {
    return {
      steps: hiddenSteps.size
        ? steps.filter((x, i) => !hiddenSteps.has(i))
        : steps,
      setStepTarget: (step: number, target: HTMLElement | null) => {
        if (!target || steps[step].target === target) {
          return;
        }

        setSteps((oldSteps) => {
          oldSteps[step] = { ...oldSteps[step], target };
          return [...oldSteps];
        });
      },
      unsetStepTarget: (step: number) => {
        if (!steps[step].target) {
          return;
        }

        setSteps((oldSteps) => {
          oldSteps[step] = { ...oldSteps[step], target: null };
          return [...oldSteps];
        });
      },
      subscribe: (cb: (current: number | null) => void) => {
        const index = subscribers.push(cb) - 1;

        return () => {
          subscribers.splice(index, 1);
        };
      },
      hiddenStep: (index: number) => {
        if (hiddenSteps.has(index)) {
          return;
        }

        setHiddenSteps((old) => {
          const newSet = new Set(old);
          newSet.add(index);
          return newSet;
        });
      },
      unhiddenStep: (index: number) => {
        if (!hiddenSteps.has(index)) {
          return;
        }

        setHiddenSteps((old) => {
          const newSet = new Set(old);
          newSet.delete(index);
          return newSet;
        });
      },
      setOpenTour: (open, step = 0) => {
        let _step = step - hiddenSteps.size;
        _step = _step < 0 ? 0 : _step;

        setCurrentStep(_step);

        subscribers.map((i) => i(_step));

        setTimeout(() => {
          setOpenTour(open);
        });
      },
      isReady: !steps.find((i) => i.target === undefined),
    };
  }, [steps, subscribers, hiddenSteps]);

  useEffect(() => {
    const listener = (event: KeyboardEvent) => {
      if (event.code === 'Escape') {
        event.stopPropagation();
        subscribers.map((i) => i(null));
        setOpenTour(false);
      }
    };

    if (openTour) {
      document.body.addEventListener('keydown', listener);
    }

    return () => {
      document.body.removeEventListener('keydown', listener);
    };
  }, [openTour, subscribers]);

  return (
    <AppTourContext.Provider value={value}>
      <Tour
        rootClassName="app-tour-wrapper"
        open={openTour && pathname === '/dashboard'}
        onChange={(current) => {
          setCurrentStep(current);
          subscribers.map((i) => i(current));
        }}
        onClose={() => {
          subscribers.map((i) => i(null));
          setOpenTour(false);
        }}
        steps={value.steps}
        zIndex={1500}
        current={currentStep}
        scrollIntoViewOptions={{ block: 'center' }}
      />
      {children}
    </AppTourContext.Provider>
  );
}

export const useAppTour = () => useContext(AppTourContext);
