import { FC } from 'react';
import { Button, Typography } from 'antd';
import { DownloadOutlined } from '@ant-design/icons';

interface FileViewerHeaderProps {
  file: Record<string, any>;
  downloading: boolean;
  onDownload: () => void;
}

const FileViewerHeader: FC<FileViewerHeaderProps> = ({
  file,
  downloading,
  onDownload,
}) => {
  return (
    <div className="sticky left-0 top-0 z-10 mt-0 w-full bg-[#0044CC] pt-0 shadow-sm">
      <div className="mx-auto flex h-12 w-full max-w-[1920px] items-center justify-between gap-4 px-4 sm:px-6 lg:px-8">
        <Typography.Title level={5} className="mb-0" style={{ color: 'white' }}>
          {file.name}
        </Typography.Title>

        <Button
          icon={<DownloadOutlined />}
          loading={downloading}
          onClick={onDownload}
        />
      </div>
    </div>
  );
};

export default FileViewerHeader;
