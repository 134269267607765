import { Result } from 'antd';
import { FC } from 'react';
import type { AxiosError } from 'axios';

interface AxiosErrorResultProps {
  error: AxiosError;
}

const AxiosErrorResult: FC<AxiosErrorResultProps> = ({ error }) => {
  return (
    <Result
      status={(error.response?.status as any) ?? 500}
      title={error.response?.status ?? 500}
      subTitle="Sorry, the page you visited does not exist."
    />
  );
};

export default AxiosErrorResult;
