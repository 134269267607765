import { Alert } from 'antd';
import { FC } from 'react';
import { useSelector } from 'react-redux';
import { UserResponse } from 'src/types';

const Campaigns: FC = () => {
  const user: UserResponse = useSelector(
    (globalState: any) => globalState.auth.user
  );

  const customer = user.contact?.customer;

  return (
    <div className="space-y-4">
      {customer && customer.dashboard_link_1 && (
        <div className="hidden min-h-[300px] w-full rounded-md bg-white px-2 py-4 shadow-xl shadow-blue-500/20 md:block">
          <iframe
            src={customer.dashboard_link_1}
            title="Dashhboard 1"
            className="h-[980px] w-full"
          ></iframe>
        </div>
      )}

      {customer && customer.dashboard_link_2 && (
        <div className="mt-4 min-h-[300px] w-full rounded-md bg-white px-2 py-4 shadow-xl shadow-blue-500/20 md:hidden">
          <iframe
            src={customer.dashboard_link_2}
            title="Dashhboard 2"
            className="h-[980px] w-full"
          ></iframe>
        </div>
      )}

      {customer && !customer.dashboard_link_1 && (
        <div className="hidden md:block">
          <Alert
            description="Our team is updating the report and it will be available soon."
            type="info"
            showIcon
          />
        </div>
      )}

      {customer && !customer.dashboard_link_2 && (
        <div className="md:hidden">
          <Alert
            description="Our team is updating the report and it will be available soon."
            type="info"
            showIcon
          />
        </div>
      )}
    </div>
  );
};

export default Campaigns;
