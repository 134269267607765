import { createSlice } from '@reduxjs/toolkit';
import { ApiResponseWithTotal } from '../../types';
import dayjs from 'dayjs';

interface WorkLogFilters {
  date_start?: string;
  date_end?: string;
}

const initialState = {
  loading: false,
  loadingTotalTime: false,
  data: null as ApiResponseWithTotal<any> | null,
  totalTime: null as null | number,
  filters: {
    date_start: dayjs().subtract(30, 'd').format('YYYY-MM-DD'),
    date_end: dayjs().format('YYYY-MM-DD'),
  } as WorkLogFilters,
  error: null,
};

export const workLogsSlice = createSlice({
  name: 'workLogs',
  initialState,
  reducers: {
    getWorkLogs: () => {},
    getWorkLogsTotalTime: () => {},
    setWorkLogsLoading: (state) => ({ ...state, loading: true }),
    setWorkLogsTotalTimeLoading: (state) => ({
      ...state,
      loadingTotalTime: true,
    }),
    setWorkLogsData: (state, action) => ({
      ...state,
      loading: false,
      data: action.payload,
    }),
    setWorkLogsTotalTime: (
      state,
      { payload }: { payload: number | null; type: string }
    ) => ({
      ...state,
      loadingTotalTime: false,
      totalTime: payload,
    }),
    setWorkLogsFilters: (
      state,
      { payload }: { payload: WorkLogFilters | null; type: string }
    ) => ({
      ...state,
      filters: payload ? { ...state.filters, ...payload } : {},
    }),
    clearWorkLogsData: (state) => ({
      ...state,
      data: null,
      loading: false,
      error: null,
    }),
    setWorkLogsError: (state, action) => ({
      ...state,
      loading: false,
      error: action.payload,
    }),
  },
});

export const {
  getWorkLogs,
  getWorkLogsTotalTime,
  setWorkLogsTotalTime,
  setWorkLogsData,
  setWorkLogsError,
  setWorkLogsLoading,
  setWorkLogsTotalTimeLoading,
  setWorkLogsFilters,
  clearWorkLogsData,
} = workLogsSlice.actions;

export default workLogsSlice.reducer;
