import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  loading: false,
  data: undefined as Record<string, any> | null | undefined,
  error: null,
};

export const projectRetainerSlice = createSlice({
  name: 'projectRetainer',
  initialState,
  reducers: {
    getProjectRetainer: () => {},
    setProjectRetainerLoading: (state) => ({ ...state, loading: true }),
    setProjectRetainerData: (state, action) => ({
      ...state,
      loading: false,
      data: action.payload,
    }),
    clearProjectRetainerData: () => initialState,
    setProjectRetainerError: (state, action) => ({
      ...state,
      loading: false,
      error: action.payload,
    }),
  },
});

export const {
  setProjectRetainerData,
  setProjectRetainerError,
  setProjectRetainerLoading,
  getProjectRetainer,
  clearProjectRetainerData,
} = projectRetainerSlice.actions;

export default projectRetainerSlice.reducer;
