import { Form, DatePicker } from 'antd';
import { FC } from 'react';
import dayjs from 'dayjs';

interface DateProps {
  question: any;
  disabled: boolean;
}

const DateInput: FC<DateProps> = ({ question, disabled }) => {
  return (
    <Form.Item
      label={question.label}
      name={question.id}
      rules={[
        { required: question.required, message: 'This field is required' },
      ]}
    >
      <DatePicker
        className={disabled ? 'read-only' : undefined}
        format="MM/DD/YYYY"
        placeholder=""
        disabledDate={(value) => value.isBefore(dayjs().startOf('day'))}
      />
    </Form.Item>
  );
};

export default DateInput;
