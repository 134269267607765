import { createSlice } from '@reduxjs/toolkit';
import { ApiResponse } from 'src/types';

export interface PendingInvitesStateType {
  loading: boolean;
  data: ApiResponse<any> | null;
  error: null | string;
}

const initialState: PendingInvitesStateType = {
  loading: false,
  data: null,
  error: null,
};

export const pendingInvitesSlice = createSlice({
  name: 'pendingInvites',
  initialState,
  reducers: {
    getPendingInvites: () => {},
    setPendingInvitesLoading: (state) => ({ ...state, loading: true }),
    setPendingInvitesData: (state, action) => ({
      ...state,
      loading: false,
      data: action.payload,
    }),
    removePendingInvite: (
      state,
      { payload }: { payload: string; type: string }
    ) => ({
      ...state,
      loading: false,
      data: state.data
        ? {
            ...state.data,
            data: state.data.data.filter((item) => item.uuid !== payload),
          }
        : null,
    }),
    clearPendingInvitesData: () => initialState,
    setPendingInvitesError: (state, action) => ({
      ...state,
      loading: false,
      error: action.payload,
    }),
  },
});

export const {
  setPendingInvitesData,
  setPendingInvitesError,
  setPendingInvitesLoading,
  getPendingInvites,
  removePendingInvite,
  clearPendingInvitesData,
} = pendingInvitesSlice.actions;

export default pendingInvitesSlice.reducer;
