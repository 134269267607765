export const loadState = (key: string, defaultValue: any) => {
  try {
    const serializedState = localStorage.getItem(key);

    if (serializedState === null) {
      return defaultValue;
    }

    return JSON.parse(serializedState);
  } catch (error) {
    return defaultValue;
  }
};

export const saveState = (key: string, obj: any) => {
  try {
    localStorage.setItem(key, JSON.stringify(obj));
  } catch (error) {}
};
